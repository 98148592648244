import Divider from 'components/atoms/Divider';
import Text from 'components/atoms/Text';
import Heading from 'components/atoms/Heading';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { useGetSurveySubmissionMutation } from 'redux/api/surveys';

export interface ISurveyResultsPanel {
	className?: string;
}

interface surveyResult {
	id: number;
	question: string;
	answer: string;
}

const SurveyResultsPanel: React.FC<ISurveyResultsPanel> = ({
	className = '',
}) => {
	const [getSurveySubmission, { data, isSuccess }] =
		useGetSurveySubmissionMutation();
	const sectionId = useAppSelector((state) => state.surveys.surveyUid);
	const [responses, setResponses] = useState<surveyResult[]>([
		{
			id: 0,
			question: 'No questions found in this survey',
			answer: 'No answers found in this survey',
		},
	]);

	useEffect(() => {
		if (sectionId) {
			getSurveySubmission({ id: sectionId });
		}
	}, [getSurveySubmission, sectionId]);

	useEffect(() => {
		if (data && isSuccess && data?.data.survey_results.length > 0) {
			setResponses(data.data.survey_results);
		}
	}, [data, isSuccess]);

	return (
		<div className={`${className} mx-12 pl-16 2xl:pl-4`}>
			<Heading type="h2" className="my-6">
				Survey Results
			</Heading>
			<Divider />
			<div className="grid grid-col grid-cols-3 my-6 gap-x-4 max-w-[1200px]">
				<div className="col-span-2 xl:min-w-[300px]">
					<Heading type="h3">Question</Heading>
				</div>
				<div>
					<Heading type="h3">Answers</Heading>
				</div>
			</div>
			<div className="grid grid-col grid-cols-3 my-6 gap-x-4 max-w-[1200px]">
				{responses.length > 0 &&
					responses.map((result: surveyResult, index: number) => {
						return (
							<>
								<Text
									key={index}
									className="col-span-2 my-6 text-sm xl:min-w-[300px]"
								>
									{`${result.id !== 0 ? `${index + 1}.` : ''} ${
										result.question
									}`}
								</Text>
								<Text key={index} className="my-6 text-sm">
									{`${result.answer}`}
								</Text>
							</>
						);
					})}
			</div>
			<Divider />
		</div>
	);
};

export default SurveyResultsPanel;
