import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { IAnswer } from 'types/AnswerReport';
import SurveyResponsesMonthlyTableCell from '../SurveyResponsesMonthlyTableCell';

export interface ISurveyResponsesMonthlyTable {
	answerData: IAnswer[];
}

const SurveyResponsesMonthlyTable: React.FC<ISurveyResponsesMonthlyTable> = ({
	answerData,
}) => {
	const headings = [
		{ id: 1, name: 'Survey Responses' },
		{ id: 2, name: 'Responses' },
		{ id: 3, name: 'State' },
		{ id: 4, name: 'Branch' },
		{ id: 5, name: 'Client List' },
	];

	return (
		<div className="flex flex-col">
			<div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
				<div className="py-2 inline-block min-w-full">
					<div className="overflow-hidden">
						<table className="min-w-full">
							<thead className="bg-white border-b">
								<tr>
									{headings.map((heading) => (
										<th
											scope="col"
											className={`text-sm font-medium text-secondary-200 pr-6 py-4 ${
												heading.id === 1 ? 'text-left' : 'text-right'
											}`}
											key={heading.id}
										>
											{heading.name}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{answerData.map((data: IAnswer, index: number) => (
									<tr
										className={`${
											index % 2 === 0 ? 'bg-white' : 'bg-gray-100'
										}`}
										key={data.id}
									>
										<td className="px-6 py-4 whitespace-nowrap text-sm text-secondary w-52">
											{data.answer}
										</td>
										<td className="text-sm text-secondary font-light px-6 py-4 whitespace-nowrap text-right">
											{data.responses}
										</td>
										<SurveyResponsesMonthlyTableCell data={data.states} />
										<SurveyResponsesMonthlyTableCell data={data.branches} />
										<SurveyResponsesMonthlyTableCell data={data.client_list} />
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SurveyResponsesMonthlyTable;
