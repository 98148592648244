import AltusGroupLogoStacked from 'assets/images/AltusGroupLogoStacked.png';
import CustomIcon from 'components/atoms/CustomIcon';
import Text from 'components/atoms/Text';
import React, { SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { INavigation } from 'types/Layout';

export interface ISideBar {
	navigation: INavigation[];
	setSelected: React.Dispatch<SetStateAction<INavigation>>;
	isSidebarHidden: boolean;
}

const SideBar: React.FC<ISideBar> = ({
	navigation,
	setSelected,
	isSidebarHidden,
}) => {
	const navLinkHover =
		'hover:bg-primary-500 hover:border-primary-500 hover:text-primary justify-center m-auto';

	const navLinkActive =
		'active:bg-[#ffd898] active:border-[#ffd898] active:text-primary justify-center m-auto';

	const navLinkDisabled =
		'disabled:bg-white disabled:border disabled:border-secondary-50 disabled:text-secondary-50 justify-center m-auto';

	return (
		<div
			className={`xl:flex flex-col flex-start w-[113px] h-full shadow-xl z-50 bg-white transition ease-in-out ${
				isSidebarHidden ? 'hidden' : ''
			}`}
		>
			<div className="h-[108px] mx-auto py-6 px-1">
				<Link to={'/dashboard'}>
					<img
						className="h-[65px]  max-w-[110px]"
						src={AltusGroupLogoStacked}
						alt="AltusLogo"
					/>
				</Link>
			</div>
			<div className="flex flex-col w-full pt-4 pb-4 gap-y-6">
				{navigation.map((item: INavigation) => (
					<button onClick={() => setSelected(item)} key={item.name}>
						<div className="flex flex-col gap-2">
							<div
								className={`
									${navLinkHover}
									${navLinkActive}
									${navLinkDisabled}
									align-middle
									m-auto
									rounded-md
									h-[42px]
									w-[42px]
								`}
							>
								<CustomIcon
									iconTypes={item.name}
									iconState={item.current ? 'active' : 'unselected'}
								/>
							</div>
							<Text className="mx-auto">{item.name}</Text>
							<div className="flex items-center justify-center border-primary-500 w-[38px] mx-auto rounded-2xl border-[1.5px] px-6 py-0.5">
								<Text className="text-primary-500">{item?.total || 0}</Text>
							</div>
						</div>
					</button>
				))}
			</div>
		</div>
	);
};

export default SideBar;
