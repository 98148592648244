import CheckBox from 'components/atoms/CheckBox';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateSurveySearchFilterState } from 'redux/slices/surveys';
import { updateFilterState } from 'redux/slices/vehicleFaultSlice';
import { updateIncidentFilterState } from 'redux/slices/vehicleIncidentsSlice';
import { IOption } from 'types/VehicleFaults';

export interface ICheckBoxGroup {
	filterName:
		| 'state'
		| 'branch'
		| 'vehicle'
		| 'priority'
		| 'status'
		| 'client'
		| 'assignee'
		| 'involved'
		| 'severity'
		| 'codeOfConduct'
		| 'incidentCategory'
		| 'lifeSavingRules'
		| 'correctiveActions';
	filterType: 'incident' | 'fault' | 'survey';
	allCheckBox?: boolean;
}
const CheckBoxGroup: React.FC<ICheckBoxGroup> = ({
	filterName,
	filterType,
	allCheckBox,
}) => {
	const dispatch = useAppDispatch();
	const filters = useAppSelector((state) => state.vehicleFault.filters);
	const incidentFilters = useAppSelector(
		(state) => state.vehicleIncident.filters
	);
	const surveyFilters = useAppSelector((state) => state.surveys.searchFilter);

	const getFilterState = () => {
		let filterSection = filters;
		if (filterType === 'incident') {
			filterSection = incidentFilters;
		} else if (filterType === 'survey') {
			filterSection = surveyFilters;
		}
		return JSON.parse(JSON.stringify(filterSection));
	};

	const updateFilterStateByType = (filterState: { [key: string]: string }) => {
		if (filterType === 'fault') {
			dispatch(updateFilterState(filterState));
		}
		if (filterType === 'incident') {
			dispatch(updateIncidentFilterState(filterState));
		}
		if (filterType === 'survey') {
			dispatch(updateSurveySearchFilterState(filterState));
		}
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const filterState = getFilterState();

		const checkBoxItems = filterState[filterName] as IOption[];
		const index = checkBoxItems.findIndex(
			(item) => item.id === Number(e.target.id)
		);
		checkBoxItems[index].checked = e.target.checked;
		filterState[filterName] = checkBoxItems;
		setIsAllChecked(false);

		updateFilterStateByType(filterState);
	};

	const [isAllChecked, setIsAllChecked] = useState<boolean>(true);
	const onChangeAll = (e: React.ChangeEvent<HTMLInputElement>) => {
		const filterState = getFilterState();

		const checkBoxItems = filterState[filterName] as IOption[];
		for (const checkBoxItem of checkBoxItems) {
			checkBoxItem.checked = e.target.checked;
		}
		filterState[filterName] = checkBoxItems;
		setIsAllChecked(e.target.checked);

		updateFilterStateByType(filterState);
	};

	return (
		<div className="flex flex-col space-y-2">
			{allCheckBox && (
				<CheckBox
					item={{
						checked: isAllChecked,
						id: 0,
						title: 'All',
						description: '',
					}}
					onChange={onChangeAll}
				/>
			)}
			<>
				{filterType === 'fault' &&
					(filters[filterName] as IOption[])?.map((item: IOption) => (
						<CheckBox key={item.id} item={item} onChange={onChange} />
					))}
			</>
			<>
				{filterType === 'incident' &&
					(incidentFilters[filterName] as IOption[])?.map((item: IOption) => (
						<CheckBox key={item.id} item={item} onChange={onChange} />
					))}
			</>
			<>
				{filterType === 'survey' &&
					(surveyFilters[filterName] as IOption[])?.map((item: IOption) => (
						<CheckBox key={item.id} item={item} onChange={onChange} />
					))}
			</>
		</div>
	);
};

export default CheckBoxGroup;
