import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { IFilters } from 'types/Surveys';
import { useGetSurveyResponsesReportQuery } from 'redux/api/reportsAPI';
import { ExportToCsv } from 'export-to-csv';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';

import React from 'react';

export interface IShare {
	label?: string;
	id?: string;
	options: { id: string; label: string }[];
	selected?: string;
	onChange?: (item: string) => void;
	placeholder?: string;
	className?: string;
	size?: 'normal' | 'small';
	required?: boolean;
	errorMessage?: string;
	disabled?: boolean;
	filter: IFilters;
	type: string;
	tab?: string;
}

const Share: React.FC<IShare> = ({
	options,
	selected: defaultSelected,
	onChange,
	placeholder = 'Select',
	className = '',
	errorMessage,
	disabled,
	filter,
	type,
	tab = '',
}) => {
	const [selected, setSelected] = useState(defaultSelected);
	const getFilterQuery = () => {
		return `${Object.keys(filter)
			.filter((key) => filter[key] && filter[key]?.length)
			.map((key) => `${key}=${filter[key]}`)
			.join('&')}&type=${type}`;
	};

	const { data } = useGetSurveyResponsesReportQuery(getFilterQuery());

	useEffect(() => {
		setSelected(defaultSelected);
	}, [defaultSelected]);

	const handleOnSelect = (item: string) => {
		setSelected(item);
		onChange?.(item);

		const timestamp = new Date().toISOString().substring(0, 19);
		const formattedType = type.replace(/\s+/g, '-').toLowerCase();
		if (item === 'CSV') {
			const totalResponses = data?.charts.length
				? data?.charts.reduce((prev, curr) =>
						prev.total > curr.total ? prev : curr
				  ).total
				: 0;
			const questionsCSV: { [key: string]: string | number }[] = [];
			data?.charts.forEach((chart) => {
				let clientIndex = 0;
				chart.answers.forEach((answer) => {
					for (let i = 0; i < answer.total; i++) {
						questionsCSV[clientIndex] = {
							...questionsCSV[clientIndex],
							[chart.question]: answer.answer,
						};
						clientIndex++;
					}
					// Add blank answers to any unanswered questions to prevent undefined from showing in the CSV
					for (let j = clientIndex; j < totalResponses; j++) {
						questionsCSV[j] = { ...questionsCSV[j], [chart.question]: '' };
					}
				});
			});

			if (questionsCSV.length) {
				const timestamp = new Date().toISOString().substring(0, 24);
				const csvTitle =
					'Filters:' +
					(filter.company ? ` Company ${filter.company} |` : '') +
					(filter.client ? ` Client ${filter.client} |` : '') +
					(filter.from ? ` From ${filter.from} |` : '') +
					(filter.end ? ` To ${filter.end} |` : '') +
					(filter.dateType === 'sent'
						? ' Sent during date range'
						: ' Responded during date range');
				const options = {
					showLabels: true,
					showTitle: true,
					title: csvTitle,
					filename: `survey-report-${timestamp}`,
					useBom: true,
					useKeysAsHeaders: true,
				};
				const csvExporter = new ExportToCsv(options);
				csvExporter.generateCsv(questionsCSV);
			}
		} else if (item === 'PDF') {
			const domElement = document.getElementById('report-panel');
			if (domElement) {
				html2canvas(domElement, {
					onclone: (document) => {
						// Hide any elements we don't want in the PDF
						document
							.querySelectorAll<HTMLElement>(
								'button, button + ul, button + span'
							)
							.forEach((hideElement) => {
								hideElement.style.visibility = 'hidden';
							});
					},
				}).then((canvas) => {
					const imgData = canvas.toDataURL('image/png');
					const imgWidth = tab === 'detailed-monthly-report' ? 210 : 270;
					const pageHeight = tab === 'detailed-monthly-report' ? 295 : 395;
					const imgHeight = (canvas.height * imgWidth) / canvas.width;
					let heightLeft = imgHeight;
					//const pdf = new jsPdf('p', 'mm', 'a4');
					const pdf = new jsPdf('p', 'mm');
					let position = 10; // give some top padding to first page

					pdf.addImage(
						imgData,
						'PNG',
						0,
						position,
						imgWidth,
						imgHeight,
						'',
						'FAST',
						0
					);
					heightLeft -= pageHeight;

					while (heightLeft >= 0) {
						position = heightLeft - imgHeight + 10; // top padding for other pages
						pdf.addPage();
						pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
						heightLeft -= pageHeight;
					}
					pdf.save(`survey-report-${formattedType}-${timestamp}.pdf`);
				});
			}
		}
	};

	const inputBaseStyle = errorMessage
		? 'block  border-red-300 focus:ring-red-500 focus:border-red-500 placeholder-red-300 focus:outline-none'
		: 'border-gray-300';

	return (
		<Listbox value={selected} onChange={handleOnSelect} disabled={disabled}>
			{({ open }) => (
				<>
					<div className={`relative ${className} ml-auto`}>
						<Listbox.Button
							className={`py-2
							border
							${open ? ' border-primary border-b-0 drop-shadow-lg' : `${inputBaseStyle}`}
							bg-primary rounded-[5px] relative  px-4 text-left focus:outline-none sm:text-sm  ${
								!disabled && 'cursor-pointer'
							}`}
						>
							<div className="flex justify-between">
								{!selected ? (
									<span className="block truncate text-organe-800">
										{placeholder}
									</span>
								) : (
									<span className="block truncate">
										{options &&
											options.filter((option) => option.label === selected)[0]
												?.label}
									</span>
								)}
								<span className="flex items-center pointer-events-none">
									{open ? (
										<ChevronUpIcon
											className="w-5 h-5 text-primary"
											aria-hidden="true"
										/>
									) : (
										<ChevronDownIcon
											className="w-5 h-5 text-secondary"
											aria-hidden="true"
										/>
									)}
								</span>
							</div>

							{open && (
								<div className="absolute bottom-0 left-0 px-4">
									<div className="h-0 border-t-[0.25px] border-primary " />
								</div>
							)}
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options
								static
								className="absolute z-10 w-full py-2 text-base bg-white border border-t-0 cursor-pointer sm:text-sm border-primary drop-shadow-lg"
							>
								{options.map((option, idx) => (
									<Listbox.Option
										key={idx}
										className={
											'text-secondary select-none py-3 px-4 cursor-pointer'
										}
										value={option.label}
									>
										<span className={'font-normal block truncate'}>
											{option.label}
										</span>
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
					{errorMessage && (
						<p className="mt-2 text-sm text-red-600" id="email-error">
							{errorMessage}
						</p>
					)}
				</>
			)}
		</Listbox>
	);
};

export default Share;
