import { IBranchesByState } from 'types/Branches';
import { baseAPI } from './baseAPI';

export const branchAPI = baseAPI.injectEndpoints({
	endpoints: (builder) => ({
		branchesByState: builder.mutation<IBranchesByState, void>({
			query() {
				return {
					url: '/branch/state',
					method: 'GET',
				};
			},
		}),
		branchesFromPerms: builder.query<{ id: number; label: string }[], void>({
			query() {
				return {
					url: '/branch/with-perms',
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useBranchesByStateMutation, useBranchesFromPermsQuery } =
	branchAPI;
