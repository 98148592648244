import { IAnswerReport, IMonthlySurveyResponse } from 'types/AnswerReport';
import { ISurveyAnswer } from 'types/Surveys';

import { baseAPI } from './baseAPI';

interface ISurveyResponsesReport {
	statistic: {
		satisfaction?: number;
		loyalty?: number;
		growth?: number;
	};
	charts: {
		id: number;
		question: string;
		type: string;
		total: number;
		answers: {
			answer: string;
			total: number;
		}[];
	}[];
	tables: {
		id: number;
		question: string;
		type: string;
		total: number;
		comment: boolean;
		suggestion: boolean;
		survey_answers: ISurveyAnswer[];
	}[];
	unsubscribed: {
		id: number;
		first_name: string;
		last_name: string;
		email: string;
		responded: boolean;
		createdAt: string;
		unsubscribedAt: string;
	}[];
}

interface ISurveyNpsScore {
	id: number;
	question: string;
	type: string;
	total: number;
	states: IAnswerReport[];
	percentage: string;
}

interface ISurveyNpsScoreReport {
	satisfaction: ISurveyNpsScore;
	loyalty: ISurveyNpsScore;
	growth: ISurveyNpsScore;
}

export const reportsAPI = baseAPI.injectEndpoints({
	endpoints: (builder) => ({
		getSurveyResponsesReport: builder.query<ISurveyResponsesReport, string>({
			query(query) {
				return {
					url: `/survey/responses-report?${query}`,
					method: 'GET',
				};
			},
		}),
		getSurveyNpsScoreReport: builder.query<ISurveyNpsScoreReport, string>({
			query(query) {
				return {
					url: `/survey/nps-score-report?${query}`,
					method: 'GET',
				};
			},
		}),
		getMonthlyReport: builder.query<IMonthlySurveyResponse, string>({
			query(query) {
				return {
					url: `/survey/monthly-report?${query}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const {
	useGetSurveyResponsesReportQuery,
	useGetSurveyNpsScoreReportQuery,
	useGetMonthlyReportQuery,
} = reportsAPI;
