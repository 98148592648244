export type SurveyResponsesTableMapper<T> = Array<{
	heading: string;
	render: (prop: T) => string | number;
}>;

export interface SurveyResponsesTableProps<T> {
	data: T[];
	mapper: SurveyResponsesTableMapper<T>;
}

function SurveyResponsesTable<T>({
	data,
	mapper,
}: SurveyResponsesTableProps<T>) {
	return (
		<table className="w-full">
			<thead className="text-xs text-left text-secondary-200">
				<tr className="border-b-2">
					{mapper.map((map) => (
						<th className="font-extralight" key={map.heading}>
							{map.heading}
						</th>
					))}
				</tr>
			</thead>
			<tbody className="text-sm text-secondary-500">
				{data?.map((row: T, index: number) => (
					<tr key={index} className="odd:bg-white even:bg-gray-100">
						{mapper.map(({ render }) => (
							<td className="w-24 p-2">{render(row)}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}

export default SurveyResponsesTable;
