import TabLink from 'components/atoms/TabLink';
import Text from 'components/atoms/Text';
import IncidentSection1 from 'components/organisms/IncidentSection1';
import IncidentSection2 from 'components/organisms/IncidentSection2';
import IncidentSection3 from 'components/organisms/IncidentSection3';
import IncidentSection4 from 'components/organisms/IncidentSection4';
import IncidentSection5 from 'components/organisms/IncidentSection5';
import React, { useEffect, useState } from 'react';
import Button from 'components/atoms/Button';
import { useGetIncidentMutation } from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';
import {
	IQueryReportSection,
	ISection1ErrorStates,
	ISection2ErrorStates,
	ISection3ErrorStates,
	ISection4ErrorStates,
	IVehicleIncidentSection1,
	IVehicleIncidentSection2,
	IVehicleIncidentSection3,
	IVehicleIncidentSection4,
	bodyParts,
	bodyPartsInjured,
	incidentAgency,
	incidentMechanism,
	injuryType,
} from 'types/VehicleIncidents';
import { XIcon } from '@heroicons/react/outline';
import { useGetIncidentSection1 } from 'utils/useGetIncidentSection1';
import { useGetIncidentSection2 } from 'utils/useGetIncidentSection2';
import { useGetIncidentSection3 } from 'utils/useGetIncidentSection3';
import { useGetIncidentSection4 } from 'utils/useGetIncidentSection4';
import { useSetErrorState } from 'utils/useSetErrorState';

export interface IIncidentPanel {
	getIncidents: (filters: string) => void;
	filters: string;
	onClose: () => void;
}

const IncidentPanel: React.FC<IIncidentPanel> = ({
	getIncidents,
	filters,
	onClose,
}) => {
	const [section, setSection] = useState<IQueryReportSection>('section1');
	const {
		incidentSection1Id,
		incidentSection2Id,
		incidentSection3Id,
		incidentSection4Id,
		incidentSection5Id,
		incidentReportId,
		isNewIncident,
	} = useAppSelector((state) => state.vehicleIncident);

	const [getIncident, { data, isSuccess }] = useGetIncidentMutation();

	useEffect(() => {
		if (!isNewIncident && incidentReportId !== null) {
			getIncident(incidentReportId);
		}
	}, [isNewIncident, incidentReportId, getIncident]);

	const [errorTabStates, setErrorTabStates] = useState({
		section1: false,
		section2: false,
		section3: false,
		section4: false,
		section5: false,
	});

	const [incidentSection1, setIncidentSection1] =
		useState<IVehicleIncidentSection1>({
			description: '',
			location: '',
			client: '',
			jobDetails: '',
			altusRegistration: '',
			driverName: '',
			licenseClass: '',
			licenseExpiry: '',
			incident_date: new Date(),
			reported_date: new Date(),
			branch: '',
			incident_time: '',
			mv_incident: false,
			thirdParty: false,
			thirdParties: [
				{
					id: 1,
					thirdParty_name: '',
					thirdParty_vehicle_registration: '',
					thirdParty_licence_number: '',
					thirdParty_phone_number: '',
				},
			],
		});

	const [incidentSection2, setIncidentSection2] =
		useState<IVehicleIncidentSection2>({
			id: null,
			personInvolved: [
				{
					id: 1,
					personInvolved_name: '',
					personInvolved_position: '',
					personInvolved_task: '',
					personInvolved_phone_number: '',
				},
			],
			witness_exists: false,
			witnesses: [
				{
					id: 1,
					witness_name: '',
					witness_position: '',
					witness_task: '',
					witness_phone_number: '',
				},
			],
		});

	const [incidentSection3, setIncidentSection3] =
		useState<IVehicleIncidentSection3>({
			bodyParts,
			bodyPartsInjured,
			bodyPartsInjuredOtherInput: '',
			injuryType,
			injuryTypeOtherInput: '',
			incidentMechanism,
			incidentMechanismOtherInput: '',
			incidentAgency,
			incidentAgencyOtherInput: '',
			injuries: false,
		});

	const [incidentSection4, setIncidentSection4] =
		useState<IVehicleIncidentSection4>({
			severityRating: '1',
			reportedTo: '',
			referenceNumber: '',
			notes: '',
			incidentCategory: [
				{
					id: 1,
					title: '',
					checked: false,
					description: '',
				},
			],
			incidentMechanism,
			incidentMechanismOtherInput: '',
			incidentAgency,
			incidentAgencyOtherInput: '',
			lifeSavingRules: [
				{
					id: 1,
					title: '',
					checked: false,
					description: '',
				},
			],
			codeOfConduct: [
				{
					id: 1,
					title: '',
					checked: false,
				},
			],
			isNotifiableIncident: false,
			isLifeSavingBreach: false,
			isCodeOfConductBreach: false,
		});

	const [section1ErrorStates, setSection1IncidentErrorStates] =
		useState<ISection1ErrorStates>({
			section_1_incident_description: false,
			section_1_location: false,
			section_1_client_name: false,
			section_1_job_details: false,
			section_1_incident_date: false,
			section_1_reported_date: false,
			section_1_incident_time: false,
		});

	const [section2ErrorStates, setSection2IncidentErrorStates] =
		useState<ISection2ErrorStates>({});

	const [section3ErrorStates, setSection3IncidentErrorStates] =
		useState<ISection3ErrorStates>({
			section_3_body_parts: false,
			section_3_injury_type: false,
			section_3_incident_mechanism: false,
			section_3_incident_agency: false,
		});

	const [section4ErrorStates, setSection4IncidentErrorStates] =
		useState<ISection4ErrorStates>({
			section_4_incident_category: false,
			section_4_reference_number: false,
			section_4_reported_to: false,
			section_4_life_saving_rules: false,
			section_4_code_of_conduct_checkbox: false,
			section_4_incident_agency: false,
			section_4_incident_mechanism: false,
		});

	useGetIncidentSection1(
		isSuccess,
		data,
		setIncidentSection1,
		setSection1IncidentErrorStates
	);

	useGetIncidentSection2(
		isSuccess,
		data,
		setIncidentSection2,
		setSection2IncidentErrorStates
	);

	useGetIncidentSection3(
		isSuccess,
		data,
		setIncidentSection3,
		setSection3IncidentErrorStates
	);

	useGetIncidentSection4(
		isSuccess,
		data,
		setIncidentSection4,
		setSection4IncidentErrorStates,
		incidentSection3.injuries
	);

	useSetErrorState(section1ErrorStates, 'section1', setErrorTabStates);
	useSetErrorState(section2ErrorStates, 'section2', setErrorTabStates);
	useSetErrorState(section3ErrorStates, 'section3', setErrorTabStates);
	useSetErrorState(section4ErrorStates, 'section4', setErrorTabStates);
	return (
		<div className="p-4 space-y-8 lg:w-[calc(100vw-10rem)]">
			<div className="flex justify-between">
				<div className="flex space-x-10">
					<TabLink
						active={section === 'section1'}
						onClick={() => setSection('section1')}
						errorExists={errorTabStates.section1}
					>
						Section 1-Description
					</TabLink>
					<TabLink
						active={section === 'section2'}
						onClick={() => setSection('section2')}
						errorExists={errorTabStates.section2}
					>
						Section 2-Person Involved
					</TabLink>
					<TabLink
						active={section === 'section3'}
						onClick={() => setSection('section3')}
						errorExists={errorTabStates.section3}
					>
						Section 3-Injury
					</TabLink>
					<TabLink
						active={section === 'section4'}
						onClick={() => setSection('section4')}
						errorExists={errorTabStates.section4}
					>
						Section 4-Analysis
					</TabLink>
					<TabLink
						active={section === 'section5'}
						onClick={() => setSection('section5')}
						errorExists={errorTabStates.section5}
					>
						Section 5-Investigation
					</TabLink>
				</div>
				<div className="ml-auto text-center">
					<Text type="bold" className="text-lg" color="text-black">
						Incident #:{' '}
						<span className="text-black text-xl">{incidentReportId}</span>
					</Text>
				</div>
				<div className="flex flex-col ml-auto justify-center text-right cursor-pointer">
					<Button onClick={onClose}>
						<XIcon height={18} width={18} />
					</Button>
				</div>
			</div>
			<div className="h-0 border-t-[1px] border-primary-200 w-full my-5" />
			{section === 'section1' && (
				<IncidentSection1
					sectionId={incidentSection1Id}
					incidentSection1={incidentSection1}
					setIncidentSection1={setIncidentSection1}
					setSection1IncidentErrorStates={setSection1IncidentErrorStates}
					setSection={setSection}
				/>
			)}
			{section === 'section2' && (
				<IncidentSection2
					sectionId={incidentSection2Id}
					incidentSection2={incidentSection2}
					setIncidentSection2={setIncidentSection2}
					setSection2IncidentErrorStates={setSection2IncidentErrorStates}
					setSection={setSection}
				/>
			)}
			{section === 'section3' && (
				<IncidentSection3
					sectionId={incidentSection3Id}
					incidentSection3={incidentSection3}
					setIncidentSection3={setIncidentSection3}
					setSection3IncidentErrorStates={setSection3IncidentErrorStates}
					setSection={setSection}
				/>
			)}
			{section === 'section4' && (
				<IncidentSection4
					sectionId={incidentSection4Id}
					incidentSection4={incidentSection4}
					setIncidentSection4={setIncidentSection4}
					setSection4IncidentErrorStates={setSection4IncidentErrorStates}
					incidentSection3Injuries={incidentSection3.injuries}
					setSection={setSection}
				/>
			)}
			{section === 'section5' && (
				<IncidentSection5
					sectionId={incidentSection5Id}
					getIncidents={getIncidents}
					filters={filters}
					completed={data?.incidentData.completed || false}
					setSection={setSection}
					setErrorTabStates={setErrorTabStates}
					incidentSection1={incidentSection1}
					incidentSection2={incidentSection2}
					incidentSection3={incidentSection3}
					incidentSection4={incidentSection4}
					section1ErrorStates={section1ErrorStates}
					section2ErrorStates={section2ErrorStates}
					section3ErrorStates={section3ErrorStates}
					section4ErrorStates={section4ErrorStates}
				/>
			)}
		</div>
	);
};

export default IncidentPanel;
