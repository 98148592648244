import Input from 'components/atoms/Input';
import React, { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { ISection2Type } from 'types/VehicleIncidents';
import Button from 'components/atoms/Button';

export interface IPersonInvolvedCard {
	handleSection2Update: (
		property: ISection2Type,
		sectionLabel: string,
		index?: number
	) => void;
	deletePersonInvolved(id: number): void;
	id: number;
	personInvolved: IPersonInvolved;
	index: number;
	isDisabled: boolean;
	onBlur?: () => void;
}

export interface IPersonInvolved {
	id: number;
	personInvolved_name: string;
	personInvolved_position: string;
	personInvolved_task: string;
	personInvolved_phone_number: string;
}

const PersonInvolved: React.FC<IPersonInvolvedCard> = ({
	handleSection2Update,
	deletePersonInvolved,
	personInvolved,
	id,
	index,
	isDisabled = false,
	onBlur,
}) => {
	const [payload, setPayload] = useState(personInvolved);

	useEffect(() => {
		setPayload(personInvolved);
	}, [personInvolved]);

	const handleOnChange = (property: ISection2Type, value: string) => {
		handleSection2Update(property, value, index);
	};

	return (
		<div className="flex gap-x-8 w-full flex-wrap my-3">
			<Input
				type="text"
				onChange={(value: string) =>
					handleOnChange('personInvolved_name', value)
				}
				value={payload.personInvolved_name}
				onBlur={onBlur}
				className="basis-1/5 grow"
				label="Name"
				required={true}
				isDisabled={isDisabled}
			/>
			<Input
				type="tel"
				onChange={(value: string) =>
					handleOnChange('personInvolved_phone_number', value)
				}
				onBlur={onBlur}
				value={payload.personInvolved_phone_number}
				className="basis-1/5 grow"
				label="Phone Number"
				required={true}
				isDisabled={isDisabled}
			/>
			<Input
				type="text"
				onChange={(value: string) =>
					handleOnChange('personInvolved_position', value)
				}
				value={payload.personInvolved_position}
				onBlur={onBlur}
				className="basis-1/5 grow"
				label="Position"
				isDisabled={isDisabled}
			/>
			<Input
				type="text"
				onChange={(value: string) =>
					handleOnChange('personInvolved_task', value)
				}
				value={payload.personInvolved_task}
				onBlur={onBlur}
				className="basis-1/5 grow"
				label="Task"
				isDisabled={isDisabled}
			/>
			<div className="flex items-center mt-6">
				<Button
					type="quinary"
					className="font-bold"
					onClick={() => deletePersonInvolved(id)}
					size="md"
				>
					<TrashIcon height={20} width={18} className="cursor-pointer" />
					&nbsp;Delete
				</Button>
			</div>
		</div>
	);
};

export default PersonInvolved;
