import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import { SurveyTable } from 'components/molecules/SurveyTable';
import React from 'react';
import { IAnswerReport } from 'types/AnswerReport';

export interface ISurveyResponsesNpsScoreCard {
	chartColors: string[];
	question: string;
	scores: IAnswerReport[];
	total: number;
}

const SurveyResponsesNpsScoreCard: React.FC<ISurveyResponsesNpsScoreCard> = ({
	chartColors,
	question,
	scores,
	total,
}) => {
	const getScore = (score: IAnswerReport) => {
		if (score.total === 0) {
			return Number(0).toFixed(2);
		}
		return (
			(((score.promoter || 0) - (score.detractor || 0)) * 100) /
			score.total
		).toFixed(2);
	};

	const getTotalPromoters = (scores: IAnswerReport[]) => {
		return (
			(scores.reduce((prev, item) => prev + (item.promoter || 0), 0) * 100) /
			total
		).toFixed(2);
	};

	const getTotalDetractors = (scores: IAnswerReport[]) => {
		return (
			(scores.reduce((prev, item) => prev + (item.detractor || 0), 0) * 100) /
			total
		).toFixed(2);
	};

	const getTotalNps = (scores: IAnswerReport[]) => {
		const promoters = scores.reduce(
			(prev, item) => prev + (item.promoter || 0),
			0
		);
		const detractors = scores.reduce(
			(prev, item) => prev + (item.detractor || 0),
			0
		);
		return (((promoters - detractors) * 100) / total).toFixed(2);
	};

	const getWidth = (score: IAnswerReport) => {
		const width = Number(getScore(score)) * 0.5;
		return width < 0 ? 0 : width;
	};

	return (
		<div className="flex flex-col w-full p-8 bg-white rounded-lg">
			<div className="flex">
				<Heading type="h2">{question}</Heading>
				<div className="flex space-x-8 ml-28">
					<div className="flex flex-col">
						<span className="font-thin">Respondents</span>
						<Heading type="h2">{total}</Heading>
					</div>
					<div className="flex flex-col">
						<span className="font-thin">Detractors</span>
						<Heading type="h2">{getTotalDetractors(scores)} %</Heading>
					</div>
					<div className="flex flex-col">
						<span className="font-thin">Promoters</span>
						<Heading type="h2">{getTotalPromoters(scores)} %</Heading>
					</div>
					<div className="flex flex-col">
						<span className="font-thin">NPS Score</span>
						<Heading type="h2">{getTotalNps(scores)}</Heading>
					</div>
				</div>
			</div>
			<div className="flex">
				<SurveyTable charColors={chartColors} isNPS={true} data={scores} />
				<div className="ml-12 mt-3">
					{scores.map((row, index) => (
						<div className="flex items-center" key={row.answer}>
							<div className="flex py-2 w-12 justify-end">
								<Text color="text-secondary-200">{row.answer}</Text>
							</div>
							<div className="flex ml-4 items-center">
								<div
									className="flex justify-end p-0.5 text-secondary-500 mr-2 h-8 items-center"
									style={{
										backgroundColor: chartColors[index],
										width: `${getWidth(row)}rem`,
									}}
								>
									{getWidth(row) > 2 && (
										<Text className="mx-0.5">{getScore(row)}</Text>
									)}
								</div>
								{getWidth(row) <= 2 && (
									<Text className="mr-4">{getScore(row)}</Text>
								)}
								<Text>{row.total} Responses</Text>
							</div>
						</div>
					))}
					<div className="flex items-center">
						<div className="flex py-2 w-12 mr-4" />
						{new Array(11).fill(null).map((_, index) => (
							<div
								className={`
									flex w-20 pl-2 h-10 items-end
									border-l-2 border-dashed border-gray-300
								`}
							>
								<Text color="text-gray-300">{index * 10}</Text>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SurveyResponsesNpsScoreCard;
