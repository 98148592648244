import Heading from 'components/atoms/Heading';
import TextareaInput from 'components/atoms/TextareaInput';
import ComboBox from 'components/atoms/ComboBox';
import DateInput from 'components/atoms/DateInput';
import ToggleButton from 'components/atoms/ToggleButton';
import Button from 'components/atoms/Button';
import { IOption, IVehicleFaultAction } from 'types/VehicleFaults';
import React, { useEffect, useState } from 'react';
import {
	IVehicleFault,
	initialFaultState,
	initialVehicleFaultActionState,
} from 'types/VehicleFaults';
import { useAppSelector } from 'redux/hooks';
import {
	useGetFaultActionsMutation,
	useUpdateFaultActionsMutation,
} from 'redux/api/vehicleFaults';
import { IMultiSelectComboBoxOption } from 'components/atoms/MultiSelectComboBox/MultiSelectComboBox';
import Text from 'components/atoms/Text';

export interface IVehicleFaultActionsPanel {}
export interface IVehicleFaultActionsPanelValidation {
	action_description: string;
	assignee: string;
	completion_date: string;
}
export const validationText: IVehicleFaultActionsPanelValidation = {
	action_description: 'Action is a required field',
	assignee: 'Assignee is a required field',
	completion_date: 'Completion Date is a required field',
};

const VehicleFaultActionsPanel: React.FC<IVehicleFaultActionsPanel> = () => {
	const [fault, setFault] = useState<IVehicleFault>(initialFaultState);
	const [users, setUsers] = useState<IMultiSelectComboBoxOption[]>([]);
	const [action, setAction] = useState<IVehicleFaultAction>(
		initialVehicleFaultActionState
	);
	const [getFaultActions, { data, isSuccess }] = useGetFaultActionsMutation();
	const [updateFaultActions] = useUpdateFaultActionsMutation();
	const faultState = useAppSelector((state) => state.vehicleFault);
	const [validationErrors, setValidationErrors] =
		useState<IVehicleFaultActionsPanelValidation>({
			action_description: '',
			assignee: '',
			completion_date: '',
		});
	const [hasInteraction, setHasInteraction] = useState(false);

	useEffect(() => {
		// @ts-ignore
		if (faultState.actionsFault?.id)
			getFaultActions(faultState.actionsFault?.id);
	}, [getFaultActions, faultState]);

	useEffect(() => {
		if (data && isSuccess) {
			setFault(data.fault);
			setUsers(data.users);

			if (Array.isArray(data?.fault?.actions)) {
				const formatActions: IVehicleFaultAction[] | undefined =
					data.fault.actions?.map((action) => ({
						id: action.id,
						action_description: action.action_description,
						action_taken: action.action_taken,
						completed: action.completed,
						completion_date: new Date(action.completion_date),
						assignee:
							typeof action.assignee === 'object' &&
							typeof action.assignee !== 'number' &&
							action.assignee
								? // @ts-ignore
								  `${action.assignee?.first_name} ${action.assignee?.last_name}`
								: '',
					}));

				setAction(
					formatActions?.length && Array.isArray(formatActions)
						? formatActions[0]
						: initialVehicleFaultActionState
				);
			}
		}
	}, [setFault, setUsers, setAction, data, isSuccess]);

	const disableIncidentWriteAccess = false;

	const handleActionUpdate = (
		property: string,
		value: string | boolean | Date | IOption[] | undefined | Number
	) => {
		const newAction: IVehicleFaultAction = {
			...action,
			[property]: value,
		};

		unsetValidationError(property);

		// @ts-ignore
		if (newAction.id) delete newAction.id;

		setAction(newAction);
	};

	const handleSave = async () => {
		if (disableIncidentWriteAccess) {
			return;
		}

		const newErrors = { ...validationErrors };

		for (const validationProperty in validationText) {
			console.log('validating', validationProperty);
			console.log('current errors', validationErrors);
			console.log('current action', action);
			if (!validateField(validationProperty)) {
				// @ts-ignore
				newErrors[validationProperty] = validationText[validationProperty];
			}
			const delay = () => new Promise((resolve) => setTimeout(resolve, 0));
			await delay();
		}

		setValidationErrors(newErrors);

		if (saveIsDisabled()) return;

		const sendAssignee =
			Number(users.find((user) => user.label === action?.assignee)?.id) ||
			undefined;

		if (fault?.id) {
			updateFaultActions({
				actions: [{ ...action, assignee: sendAssignee }],
				faultId: fault.id,
			});

			setHasInteraction(false);
		}
	};

	const formIsValid = () => {
		for (const property in validationErrors) {
			if (
				validationErrors.hasOwnProperty(property) &&
				// @ts-ignore
				validationErrors[property]
			) {
				return false;
			}
		}

		return true;
	};

	const saveIsDisabled = () => {
		return !hasInteraction || !formIsValid();
	};

	/**
	const setValidationError = (field: string) => {
		const newErrors = {
			...validationErrors,
			// @ts-ignore
			[field]: validationText[field],
		};
		setValidationErrors(newErrors);
	};
	*/

	const unsetValidationError = (field: string) => {
		const newErrors = {
			...validationErrors,
			[field]: '',
		};
		setValidationErrors(newErrors);
	};

	const validateField = (fieldName: string) => {
		// @ts-ignore
		if (!action[fieldName] || action[fieldName].length < 1) {
			return false;
		} else {
			return true;
		}
	};

	return (
		<div className="px-4 space-y-6">
			<div className="flex items-center justify-between space-x-4">
				<Heading type="h2">Corrective Action</Heading>
			</div>
			{saveIsDisabled() &&
				// @ts-ignore
				Object.values(validationErrors)
					.filter((item) => item.length)
					.map((error) => <Text>{error}</Text>)}
			{action && users && (
				<>
					<div className="flex flex-row">
						<TextareaInput
							id="5"
							className="w-[700px]"
							value={action.action_description}
							rows={5}
							label={`Action`}
							required={true}
							placeholder=""
							resize={true}
							onChange={(value: string) => {
								if (!hasInteraction) setHasInteraction(true);
								handleActionUpdate('action_description', value);
							}}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<div>
						<ComboBox
							label="Assign to"
							options={users}
							isDisabled={disableIncidentWriteAccess}
							required={true}
							onChange={(value: string) => {
								if (!hasInteraction) setHasInteraction(true);
								handleActionUpdate('assignee', value);
							}}
							// @ts-ignore
							selected={action.assignee}
							className="mb-10 w-96"
						/>
					</div>
					<div className="flex flex-row">
						<TextareaInput
							id="6"
							className="w-full"
							value={action.action_taken}
							rows={5}
							label={`Action Taken`}
							placeholder=""
							resize={true}
							onChange={(value: string) => {
								if (!hasInteraction) setHasInteraction(true);
								handleActionUpdate('action_taken', value);
							}}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<div>
						<DateInput
							placeholder="Select Date"
							onChange={(value: Date) => {
								if (!hasInteraction) setHasInteraction(true);
								handleActionUpdate('completion_date', value);
							}}
							onBlur={() => {
								if (!hasInteraction) setHasInteraction(true);
							}}
							className="w-60"
							label={`Completion Date`}
							iconClassName="text-black"
							// @ts-ignore
							selected={action.completion_date}
							wrapperClassName="react-datepicker-margin-0"
							isDisabled={disableIncidentWriteAccess}
							required={true}
						/>
					</div>
					<div>
						<ToggleButton
							toggle={action.completed}
							onToggle={(value: boolean) => {
								if (!hasInteraction) setHasInteraction(true);
								handleActionUpdate('completed', value);
							}}
							label={`Completed`}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<div className="flex flex-row-reverse">
						<Button
							onClick={handleSave}
							type="primary"
							className="font-bold w-32"
							isDisabled={saveIsDisabled()}
						>
							Save
						</Button>
					</div>
				</>
			)}
		</div>
	);
};

export default VehicleFaultActionsPanel;
