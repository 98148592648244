import { IUserPermissions } from 'types/User';

/**
 * Checks to see if the user has write access to a fault, incident, or survey
 *
 * @param {string} writeAccessType - the type of write access to check for
 * @return {boolean} has access to write
 */

export const disableWriteAccess = (
	writeAccessType: 'fault_level' | 'incident_level' | 'survey_level',
	userPermission: IUserPermissions[] | undefined,
	id: string | null
) => {
	const permission = userPermission?.find(
		(permission) =>
			permission.branch?.id.toString() === id &&
			permission[writeAccessType] === 'write'
	);

	return !permission;
};
