import { IMultiSelectComboBoxOption } from 'components/atoms/MultiSelectComboBox/MultiSelectComboBox';
import { baseAPI } from './baseAPI';
import {
	IVehicleSurveySubmissionResponse,
	IVehicleSurveySubmissions,
	ISortSurveyData,
} from 'types/Surveys';
interface IAssignPayload {
	id: number;
	body: {
		assignees: IMultiSelectComboBoxOption[];
		survey_status: 'submitted' | 'open' | 'closed';
	};
}

interface ISubmitSurveyPayload {
	uid: string;
	body: {
		answers: {
			questionId: number;
			answer: string;
		}[];
	};
}

export interface IVehicleSurveyCommentPayload {
	survey_submission?: number;
	type: 'comment';
	content: string;
	user: number;
}

export interface IVehicleSurveySubmissionActivity {
	content: string;
	createdAt: Date;
	id: number;
	type: 'comment' | 'status' | 'assign';
	user?: {
		id: string;
		first_name: string;
		last_name: string;
		avatar?: {
			url: string;
		};
	};
}

interface ISurveyCompany {
	id: number;
	brand_colour: string;
	logo: {
		url: string;
	};
}

export const inventoryAPI = baseAPI.injectEndpoints({
	endpoints: (builder) => ({
		getVehicleSurveySubmissions: builder.mutation<
			{ data: IVehicleSurveySubmissions[]; sortData: ISortSurveyData[] },
			string
		>({
			query(query) {
				return {
					url: `/survey-submissions?${query}`,
					method: 'GET',
				};
			},
		}),
		getSurveySubmission: builder.mutation<
			{
				data: IVehicleSurveySubmissionResponse;
				surveyCompany?: ISurveyCompany;
			},
			{ id: string; companyId?: string | null }
		>({
			query({ id, companyId }) {
				return {
					url: `/survey-submissions/${id}?companyId=${companyId}`,
					method: 'GET',
				};
			},
		}),
		submitSurveySubmission: builder.mutation<void, ISubmitSurveyPayload>({
			query(payload) {
				return {
					url: `/survey-submissions/submit/${payload.uid}`,
					method: 'PUT',
					body: payload.body,
				};
			},
		}),
		assignSurveySubmission: builder.mutation<void, IAssignPayload>({
			query(payload) {
				return {
					url: `/survey-submissions/${payload.id}`,
					method: 'PUT',
					body: payload.body,
				};
			},
		}),
		createSurveySubmissionActivity: builder.mutation<
			void,
			IVehicleSurveyCommentPayload
		>({
			query(payload) {
				return {
					url: `/survey-activities`,
					method: 'POST',
					body: { data: payload },
				};
			},
		}),
		getSurveySubmissionActivity: builder.mutation<
			IVehicleSurveySubmissionActivity[],
			number
		>({
			query(surveyId) {
				return {
					url: `/survey-submissions/${surveyId}/activity`,
					method: 'GET',
				};
			},
		}),
		deleteSurveySubmissionActivity: builder.mutation<void, number>({
			query(activityId) {
				return {
					url: `/survey-activities/${activityId}`,
					method: 'DELETE',
				};
			},
		}),
		updateSurveySubmissionActivity: builder.mutation<
			void,
			{ activityId: number; content: string }
		>({
			query(payload) {
				return {
					url: `/survey-activities/${payload.activityId}`,
					method: 'PUT',
					body: payload,
				};
			},
		}),
		getBranches: builder.query<{ id: string; label: string }[], void>({
			query() {
				return {
					url: '/branches',
					method: 'GET',
				};
			},
		}),
	}),
});

export const {
	useGetVehicleSurveySubmissionsMutation,
	useSubmitSurveySubmissionMutation,
	useAssignSurveySubmissionMutation,
	useCreateSurveySubmissionActivityMutation,
	useGetSurveySubmissionActivityMutation,
	useGetSurveySubmissionMutation,
	useUpdateSurveySubmissionActivityMutation,
	useDeleteSurveySubmissionActivityMutation,
	useGetBranchesQuery,
} = inventoryAPI;
