import React from 'react';
import Text from 'components/atoms/Text';
import Accordion from 'components/molecules/Accordion';
import SurveyResponsesMonthlyTable from '../SurveyResponsesMonthlyTable';
import { IMonthSurveyResponse, IQuestion } from 'types/AnswerReport';

export interface ISurveyResponsesMonthlyReportCard {
	data: IMonthSurveyResponse[];
}

const SurveyResponsesMonthlyReportCard: React.FC<
	ISurveyResponsesMonthlyReportCard
> = ({ data }) => {
	return (
		<>
			{data?.map((monthData: IMonthSurveyResponse) => (
				<div className="flex" key={monthData.id}>
					<Text
						color="text-primary"
						type="bold"
						className="min-w-[70px] text-sm"
					>
						{monthData?.month}
					</Text>
					<div className="ml-6 p-8 bg-white rounded-md w-full">
						{monthData?.questions.map((question: IQuestion) => (
							<Accordion
								title={question.question}
								width="w-full"
								titleColour="secondary"
								leadingChevron
								key={question?.id}
							>
								<div className="ml-10 text-sm">
									<Text>Total Responses: {question.responses}</Text>
									<SurveyResponsesMonthlyTable
										answerData={question.answers}
										key={question?.id}
									/>
								</div>
							</Accordion>
						))}
					</div>
				</div>
			))}
		</>
	);
};

export default SurveyResponsesMonthlyReportCard;
