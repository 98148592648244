import Button from 'components/atoms/Button';
import DateInput from 'components/atoms/DateInput';
import Divider from 'components/atoms/Divider';
import Select from 'components/atoms/Select';
import Accordion from 'components/molecules/Accordion';
import CheckBoxGroup from 'components/molecules/CheckBoxGroup';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetVehicleSurveySubmissionsMutation } from 'redux/api/surveys';
import { useAppSelector } from 'redux/hooks';
import {
	resetSurveySearchFilterState,
	updateSurveySearchFilterState,
} from 'redux/slices/surveys';
import { surveyStatusOptions } from 'types/Surveys';
import { surveyTypeOptions } from 'types/Surveys';
import { filtersToQueryStringConverter } from 'utils/filters';

export interface ISurveySearchBar {
	setFilters: React.Dispatch<React.SetStateAction<string>>;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SurveySearchBar: React.FC<ISurveySearchBar> = ({
	setFilters,
	setOpen,
}) => {
	const dispatch = useDispatch();
	const filters = useAppSelector((state) => state.surveys.searchFilter);
	const [getSurveys, { data }] = useGetVehicleSurveySubmissionsMutation();
	const [filterData, setFilterData] = useState(data?.sortData);

	const onClick = () => {
		const query = filtersToQueryStringConverter(filters);
		setFilters(query);
		setOpen(false);
		const filterState = JSON.parse(JSON.stringify(filters));
		dispatch(updateSurveySearchFilterState(filterState));
		dispatch(resetSurveySearchFilterState());
	};

	useEffect(() => {
		getSurveys('all');
	}, [getSurveys]);

	useEffect(() => {
		setFilterData(data?.sortData);
	}, [data?.sortData]);

	useEffect(() => {
		if (data?.sortData && filterData !== undefined) {
			const branchData = data?.sortData[0]?.branch;

			const filterState = JSON.parse(JSON.stringify(filters));
			filterState['branch'] = branchData;
			dispatch(updateSurveySearchFilterState(filterState));
		}

		//removes dependency for "filters"
		// eslint-disable-next-line
	}, [setFilterData, filterData, dispatch, data?.sortData]);

	return (
		<>
			<Divider />
			<Accordion title="Date Range">
				<DateInput
					inputLabel="from"
					placeholder="From"
					filterDateType="survey"
					iconClassName="text-secondary"
				/>
				<DateInput
					inputLabel="to"
					placeholder="To"
					filterDateType="survey"
					iconClassName="text-secondary"
				/>
			</Accordion>
			<Divider />
			<Accordion title="Status">
				<Select
					options={surveyStatusOptions}
					className="mb-10"
					selected={''}
					filterType="survey"
					filterName="status"
				/>
			</Accordion>
			<Divider />
			<Accordion title="Branch">
				<CheckBoxGroup filterName="branch" filterType="survey" allCheckBox />
			</Accordion>
			<Divider />
			<Accordion title="Type">
				<Select
					options={surveyTypeOptions}
					className="w-48 z-[35]"
					selected={''}
					filterType="survey"
					filterName="surveySearchType"
				/>
			</Accordion>
			<Button
				className="h-12 w-[222px] mx-auto my-6 font-bold"
				onClick={onClick}
			>
				Apply
			</Button>
		</>
	);
};

export default SurveySearchBar;
