import { Link, Navigate } from 'react-router-dom';
import { LockClosedIcon, UserIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import AltusGroupLogoStacked from 'assets/images/AltusGroupLogoStacked.png';
import Background from 'assets/images/login-background.png';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import MicrosoftLogo from 'assets/images/microsoft-logo.png';
import Text from 'components/atoms/Text';
import { useLoginMutation } from 'redux/api/authAPI';

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loginError, setError] = useState('');
	const [redirect, setRedirect] = useState(false);
	const token = localStorage.getItem('accessToken');
	const [login, { data, isError, error, isSuccess }] = useLoginMutation();

	useEffect(() => {
		if (isSuccess && data) {
			localStorage.setItem('accessToken', data.jwt);
			setRedirect(true);
		}
	}, [isSuccess, data, setRedirect]);

	useEffect(() => {
		if (isError && error) {
			try {
				if ('error' in error) {
					setError(error.error);
				}
			} catch (err) {
				console.error(err);
			}
		}
	}, [isError, error]);

	if (redirect || token) {
		return <Navigate to="/dashboard" />;
	}

	const onSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		const payload = {
			identifier: email,
			password,
		};
		login(payload).catch((e) => console.error(e));
	};

	return (
		<div
			className="h-screen w-full bg-gray-500 flex items-center justify-center"
			style={{
				backgroundImage: 'url(' + Background + ')',
				backgroundSize: '100% 100%',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<form
				className="bg-white h-screen sm:h-auto p-4 sm:p-12 xl:p-24 max-w-[600px] w-full space-y-8 rounded-md shadow-lg"
				onSubmit={onSubmit}
			>
				<img
					alt="altus logo"
					className="h-40 mx-auto"
					src={AltusGroupLogoStacked}
				/>
				{process.env.ENVIRONMENT !== 'production' && (
					<>
						<Heading type="h2" className="text-center w-full">
							Login
						</Heading>
						{loginError && <div>{loginError}</div>}
						<Input
							label="Email / Username"
							id="email"
							type="email"
							placeholder="Enter email"
							value={email}
							onChange={setEmail}
							iconPosition="trailing"
							Icon={UserIcon}
						/>

						<Input
							label="Password"
							type="password"
							id="password"
							placeholder="Enter password"
							value={password}
							onChange={setPassword}
							iconPosition="trailing"
							Icon={LockClosedIcon}
						/>

						<Button
							navigate="next"
							className="w-full h-[56px] font-semibold"
							isSubmit
						>
							Login
						</Button>

						<div className="flex justify-center">
							<Link to="/forgot-password">Forgot password?</Link>
						</div>

						<Text className="text-center">OR</Text>
					</>
				)}

				<div className="flex justify-center items-center">
					<img alt="Microsoft Logo" className="h-10 mx-3" src={MicrosoftLogo} />
					<Heading type="h2">Sign in with Microsoft</Heading>
				</div>

				<a
					className="flex justify-center items-center"
					href={`${process.env.REACT_APP_ALTUS_API}/connect/microsoft`}
				>
					<Button navigate="next" className="w-full h-[56px] font-semibold">
						Sign In Now
					</Button>
				</a>
			</form>
		</div>
	);
};

export default Login;
