import Button from 'components/atoms/Button';
import DateInput from 'components/atoms/DateInput';
import Divider from 'components/atoms/Divider';
import Input from 'components/atoms/Input';
import Radio from 'components/atoms/Radio';
import Select from 'components/atoms/Select';
import Text from 'components/atoms/Text';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetBranchesQuery } from 'redux/api/surveys';
import { useAppSelector } from 'redux/hooks';
import {
	openSurveyReportModal,
	updateLeadFilterState,
} from 'redux/slices/surveys';
import { stateOptions } from 'types/Surveys';

export interface ILeadFeedbackSearchBar {
	setFilters: React.Dispatch<React.SetStateAction<string>>;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeadFeedbackSearchBar: React.FC<ILeadFeedbackSearchBar> = ({
	setOpen,
}) => {
	const dispatch = useDispatch();

	const filters = useAppSelector((state) => state.surveys.leadReportFilter);
	const { data: branchData, isSuccess: branchIsSuccess } =
		useGetBranchesQuery();
	const [branchOptions, setBranchOptions] = useState([
		{
			id: '',
			label: '',
		},
	]);

	useEffect(() => {
		if (branchIsSuccess && branchData) {
			setBranchOptions(branchData);
		}
	}, [branchIsSuccess, branchData]);

	const onClick = () => {
		setOpen(false);
		dispatch(openSurveyReportModal());
	};

	const onHandleChange = (
		filterType: string,
		value: React.ChangeEvent<HTMLInputElement> | string | Date
	) => {
		const filterState = JSON.parse(JSON.stringify(filters));
		if (value instanceof Date) {
			filterState[filterType] = value.toISOString();
		} else {
			filterState[filterType] = value;
		}

		dispatch(updateLeadFilterState(filterState));
	};

	return (
		<div className="max-w-[222px] space-y-4 mx-auto">
			<Text type="bold">Date Range</Text>
			<DateInput
				placeholder="From"
				onChange={(value: Date) => onHandleChange('from', value)}
				iconClassName="text-secondary"
			/>
			<DateInput
				placeholder="End"
				onChange={(value: Date) => onHandleChange('end', value)}
				iconClassName="text-secondary"
			/>
			<Radio
				className="mt-5 mb-7"
				options={[
					{ title: 'Responded During Date Range', id: 'responded' },
					{ title: 'Sent During Date Range', id: 'sent' },
				]}
				defaultId={'responded'}
				onChange={(value: string) => onHandleChange('dateType', value)}
			/>
			<Divider />
			<Select
				label="State"
				options={stateOptions}
				onChange={(value: string) => onHandleChange('state', value)}
				className="mb-10"
				selected={''}
				placeholder="All States"
			/>
			<Divider />
			<Select
				label="Branches"
				options={branchOptions}
				onChange={(value: string) => onHandleChange('branch', value)}
				className="mb-10"
				selected={''}
				placeholder="All Branches"
			/>
			<Divider />
			<Text type="bold">Employee (optional)</Text>
			<Input
				type="text"
				onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
					onHandleChange('employee', value)
				}
				value={filters['employee'] as string}
				placeholder="Full Name"
				size="normal"
				className="mt-1"
			/>
			<Divider />
			<Button className="h-12 w-full mx-auto my-6 font-bold" onClick={onClick}>
				Run Report
			</Button>
		</div>
	);
};

export default LeadFeedbackSearchBar;
