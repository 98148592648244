import { IGetUser, ILoginPayload } from 'types/User';
import { baseAPI } from './baseAPI';

export const authAPI = baseAPI.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<
			ILoginPayload,
			{ identifier: string; password: string }
		>({
			query(body) {
				return {
					url: '/auth/local',
					method: 'POST',
					body,
				};
			},
		}),
		microsoftLogin: builder.mutation<ILoginPayload, string>({
			query(accessToken) {
				return {
					url: `/auth/microsoft/callback?access_token=${accessToken}`,
					method: 'GET',
				};
			},
		}),
		getUser: builder.mutation<IGetUser, string>({
			query(token) {
				return {
					url: '/users/me',
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
			},
		}),
	}),
});

export const {
	useLoginMutation,
	useMicrosoftLoginMutation,
	useGetUserMutation,
} = authAPI;
