import React from 'react';
import StatusButton from 'components/atoms/StatusButton';
import { Link } from 'react-router-dom';
import { useBranchesFromPermsQuery } from 'redux/api/branchAPI';
import {
	IIncidentCategoryBreakdownFilters,
	IIncidentCategorySummary,
} from 'types/VehicleIncidents';

export interface IDashboardIncidentCategoryBreakdownCard {
	className?: string;
	categories: IIncidentCategorySummary[] | undefined;
	incidentPath?: string;
	getExtraFilters?: () => IIncidentCategoryBreakdownFilters;
}

const DashboardIncidentCategoryBreakdownCard: React.FC<
	IDashboardIncidentCategoryBreakdownCard
> = ({
	className = '',
	categories,
	incidentPath,
	getExtraFilters = () => ({}),
}) => {
	var incident_total: JSX.Element | string = '';
	const extraFilters: IIncidentCategoryBreakdownFilters = {
		...getExtraFilters(),
	};

	const { data: branchData } = useBranchesFromPermsQuery();

	const currentBranch = branchData?.find((branch) => {
		return branch.id === extraFilters.branchId;
	});

	delete extraFilters.branchId;

	if (currentBranch) {
		//@ts-ignore
		extraFilters.branch_name = currentBranch.label;
	}

	//@ts-ignore
	const urlString = new URLSearchParams(extraFilters).toString();

	return (
		<table className={`${className} px-8 w-full`}>
			<thead className="border-b border-gray-200">
				<tr>
					<th className="text-secondary font-normal text-left px-4 pb-2">
						Category
					</th>
					<th className="text-secondary font-normal text-left px-4 ">Total</th>
					<th className="text-secondary font-normal text-center px-4 ">
						Status
					</th>
				</tr>
			</thead>
			<tbody>
				{categories?.map((category: IIncidentCategorySummary) => {
					if (incidentPath === 'incidents') {
						incident_total = (
							<Link
								to={`/${incidentPath}?category=${category.name}&${urlString}`}
							>
								{category.statistics.total}
							</Link>
						);
					} else {
						incident_total = <>{category.statistics.total}</>;
					}
					return (
						<tr className="border-b border-gray-200" key={category.id}>
							<td className="px-4 py-5 font-medium">{category.name}</td>
							<td className="px-4 py-5 font-medium underline">
								{incident_total}
							</td>
							<td>
								<div className="flex flex-row gap-x-2">
									{category.statistics.draft > 0 && (
										<StatusButton
											status="draft"
											category={category.name}
											incidentPath={incidentPath}
											total={category.statistics.draft}
											isDashboardStatus
											extraLinkQuery={urlString}
										/>
									)}
									{category.statistics.submitted > 0 && (
										<StatusButton
											status="submitted"
											category={category.name}
											incidentPath={incidentPath}
											total={category.statistics.submitted}
											isDashboardStatus
											extraLinkQuery={urlString}
										/>
									)}
									{category.statistics.open > 0 && (
										<StatusButton
											status="open"
											category={category.name}
											incidentPath={incidentPath}
											isDashboardStatus
											total={category.statistics.open}
											extraLinkQuery={urlString}
										/>
									)}
									{category.statistics.closed > 0 && (
										<StatusButton
											status="closed"
											category={category.name}
											incidentPath={incidentPath}
											isDashboardStatus
											total={category.statistics.closed}
											extraLinkQuery={urlString}
										/>
									)}
								</div>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default DashboardIncidentCategoryBreakdownCard;
