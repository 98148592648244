import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

export interface IMultiSelectComboBoxOption {
	id: string;
	label: string;
}

interface MultiSelectComboBoxProps {
	options: IMultiSelectComboBoxOption[];
	className?: string;
	placeholder?: string;
	maxNumberToScroll?: 2 | 3 | 4 | 5 | 6;
	selectedAssignees: IMultiSelectComboBoxOption[];
	setSelectedAssignees: React.Dispatch<
		React.SetStateAction<IMultiSelectComboBoxOption[]>
	>;
	label?: string;
	onBlur?: () => void;
	required?: boolean;
	customSelectMessage?: string;
}

const MultiSelectComboBox: React.FC<MultiSelectComboBoxProps> = ({
	options,
	className,
	placeholder = 'Select',
	maxNumberToScroll = 6,
	selectedAssignees,
	setSelectedAssignees,
	label,
	onBlur,
	required = false,
	customSelectMessage = 'options',
}) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const NUMBER_TO_SCROLL = 3;
	let scrollHeightStyle = 'max-h-72';

	const handleOnBlur = () => {
		if (!onBlur || showDropdown) return;
		onBlur();
	};

	if (maxNumberToScroll) {
		switch (maxNumberToScroll) {
			case 2:
				scrollHeightStyle = 'max-h-24';
				break;
			case 3:
				scrollHeightStyle = 'max-h-36';
				break;
			case 4:
				scrollHeightStyle = 'max-h-48';
				break;
			case 5:
				scrollHeightStyle = 'max-h-60';
				break;
			default:
				scrollHeightStyle = 'max-h-72';
		}
	}

	const handleOptionToggle = (option: React.ChangeEvent<HTMLInputElement>) => {
		const optionId = Number(option.target.id);
		const optionLabel = option.target.value;

		if (selectedAssignees.some((item) => item.id === optionId.toString())) {
			setSelectedAssignees((prevOptions) =>
				prevOptions.filter((item) => item.id !== optionId.toString())
			);
		} else {
			setSelectedAssignees((prevOptions) => [
				...prevOptions,
				{ id: optionId.toString(), label: optionLabel },
			]);
		}
	};

	return (
		<div className={`${className} relative`}>
			{label && (
				<label className="block font-inter text-base font-bold tracking-normal text-secondary mb-1">
					{label} {required && <span className="text-red">*</span>}
				</label>
			)}
			<button
				className={`py-3 px-5 bg-white w-full border focus:ring-gray-300 text-left text-sm flex justify-between ${
					showDropdown
						? 'border-x-primary border-t-primary rounded-t-md'
						: 'border-gray-300 rounded-md'
				}`}
				onClick={() => setShowDropdown((prevState) => !prevState)}
				onBlur={handleOnBlur}
				type="button"
			>
				{selectedAssignees.length > 0 ? (
					<span className="rounded-lg px-2 py-1 text-xs bg-gray-200">
						{selectedAssignees.length === 1
							? `1 ${customSelectMessage.slice(
									0,
									customSelectMessage.length - 1
							  )} selected`
							: `${selectedAssignees.length} ${customSelectMessage} selected`}
					</span>
				) : (
					<span className="block truncate flex-1 text-secondary-200">
						{placeholder}
					</span>
				)}
				<span className="flex items-center">
					{showDropdown ? (
						<ChevronUpIcon
							className="h-5 w-5 text-primary"
							aria-hidden="true"
						/>
					) : (
						<ChevronDownIcon
							className="h-5 w-5 text-secondary"
							aria-hidden="true"
						/>
					)}
				</span>
			</button>
			{showDropdown && (
				<div
					className={`bg-white absolute w-full z-20 border rounded-b-md border-primary ${
						options.length > NUMBER_TO_SCROLL
							? `overflow-y-auto ${scrollHeightStyle}`
							: ''
					}`}
				>
					{options?.length > 0 &&
						options.map((option) => (
							<div key={option.id} className="hover:bg-primary-50 px-5 py-2">
								<input
									type="checkbox"
									id={option.id}
									name={option.label}
									value={option.label}
									onChange={(e) => handleOptionToggle(e)}
									className="focus:ring-primary-500 h-[18px] w-[18px] text-primary-500 rounded bg-white mr-4 border-gray-300 my-auto cursor-pointer"
									checked={selectedAssignees.some(
										(item) => Number(item.id) === Number(option.id)
									)}
								/>
								<label htmlFor={option.id} className="cursor-pointer">
									{option.label}
								</label>
							</div>
						))}
				</div>
			)}
		</div>
	);
};

export default MultiSelectComboBox;
