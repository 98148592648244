import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import SurveyResponsesMonthlyReportCard from 'components/molecules/SurveyResponsesMonthlyReportCard';
import React from 'react';
import { IFilters } from 'types/Surveys';
import { useGetMonthlyReportQuery } from 'redux/api/reportsAPI';

export interface IMonthlyReportSection {
	type: string;
	filter: IFilters;
}

const MonthlyReportSection: React.FC<IMonthlyReportSection> = ({
	type,
	filter,
}) => {
	const getFilterQuery = () => {
		return `${Object.keys(filter)
			.filter((key) => filter[key] && filter[key]?.length)
			.map((key) => `${key}=${filter[key]}`)
			.join('&')}&type=${type}`;
	};
	const { data } = useGetMonthlyReportQuery(getFilterQuery());

	return (
		<div className="flex flex-col mt-8 space-y-8">
			<div className="flex p-8 space-x-8 bg-white rounded-lg w-fit my-4">
				<div>
					<Text color="text-primary text-sm font-thin pb-2">Response Rate</Text>
					<Heading type="h2" colour="primary">
						{data && `${Math.round(data.statistics.responseRate * 10) / 10}%`}
					</Heading>
				</div>
				<div>
					<Text color="text-secondary text-sm font-thin pb-2">
						Overal Total Responded
					</Text>
					<Heading type="h2">{data?.statistics.totalResponded}</Heading>
				</div>
				<div>
					<Text color="text-secondary text-sm font-thin pb-2">
						Unsubscribed
					</Text>
					<Heading type="h2">{data?.statistics.unsubscribed}</Heading>
				</div>
			</div>
			{data && <SurveyResponsesMonthlyReportCard data={data.months} />}
		</div>
	);
};

export default MonthlyReportSection;
