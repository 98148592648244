import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { IAuthError } from 'types/User';

export const useDisplayErrorMessage = (
	isError: boolean,
	error: FetchBaseQueryError | SerializedError | undefined,
	setError: (error: string) => void
) => {
	useEffect(() => {
		if (isError && error) {
			let errorMessage = 'Something went wrong';
			if ('error' in error) {
				errorMessage = error.error;
			} else if ('data' in error) {
				const errorResponse = error.data as IAuthError;
				errorMessage = errorResponse.error.message;
			}

			setError(errorMessage);
		}
	}, [isError, error, setError]);
};
