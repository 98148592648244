import Input from 'components/atoms/Input';
import React, { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { ISection2Type } from 'types/VehicleIncidents';
import Button from 'components/atoms/Button';

export interface IWitnessCard {
	handleSection2Update: (
		property: ISection2Type,
		sectionLabel: string,
		index?: number
	) => void;
	deleteWitness(id: number): void;
	id: number;
	witness: IWitness;
	index: number;
	isDisabled: boolean;
	onBlur?: () => void;
}

export interface IWitness {
	id: number;
	witness_name: string;
	witness_position: string;
	witness_task: string;
	witness_phone_number: string;
}

const WitnessCard: React.FC<IWitnessCard> = ({
	handleSection2Update,
	deleteWitness,
	witness,
	id,
	index,
	isDisabled = false,
	onBlur,
}) => {
	const [payload, setPayload] = useState(witness);

	useEffect(() => {
		setPayload(witness);
	}, [witness]);

	const handleOnChange = (property: ISection2Type, value: string) => {
		handleSection2Update(property, value, index);
	};

	return (
		<div className="flex gap-x-8 w-full flex-wrap my-3">
			<Input
				type="text"
				onChange={(value: string) => handleOnChange('witness_name', value)}
				value={payload.witness_name}
				onBlur={onBlur}
				className="basis-1/5 grow"
				label="Name"
				required={true}
				isDisabled={isDisabled}
			/>
			<Input
				type="tel"
				onChange={(value: string) =>
					handleOnChange('witness_phone_number', value)
				}
				onBlur={onBlur}
				value={payload.witness_phone_number}
				className="basis-1/5 grow"
				label="Phone Number"
				required={true}
				isDisabled={isDisabled}
			/>
			<Input
				type="text"
				onChange={(value: string) => handleOnChange('witness_position', value)}
				value={payload.witness_position}
				onBlur={onBlur}
				className="basis-1/5 grow"
				label="Position"
				isDisabled={isDisabled}
			/>
			<Input
				type="text"
				onChange={(value: string) => handleOnChange('witness_task', value)}
				value={payload.witness_task}
				onBlur={onBlur}
				className="basis-1/5 grow"
				label="Task"
				isDisabled={isDisabled}
			/>
			<div className="flex items-center mt-6">
				<Button
					type="quinary"
					className="font-bold"
					onClick={() => deleteWitness(id)}
					size="md"
				>
					<TrashIcon height={20} width={18} className="cursor-pointer" />
					&nbsp;Delete
				</Button>
			</div>
		</div>
	);
};

export default WitnessCard;
