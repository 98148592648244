import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartOptions,
	TooltipItem,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
	IFault,
	IIncident,
	IMonth,
	IMonthBranch,
	ISurvey,
} from 'types/Branches';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export interface IJobBreakdownGroupBarGraph {
	monthData: IMonth[];
}

const JobBreakdownGroupBarGraph: React.FC<IJobBreakdownGroupBarGraph> = ({
	monthData,
}) => {
	const options: ChartOptions<'bar'> = {
		plugins: {
			legend: {
				align: 'start',
				fullSize: true,
				labels: {
					usePointStyle: true,
					pointStyle: 'rect',
					boxHeight: 10,
					font: {
						size: 12,
					},
				},
			},
			tooltip: {
				displayColors: true,
				callbacks: {
					//removes default tooltip body information
					label() {
						return '';
					},
					afterBody(context: TooltipItem<'bar'>[]) {
						const currentMonth = monthData.find(
							(currentMonthData: IMonth) =>
								currentMonthData.month === context[0].label
						);

						if (currentMonth) {
							const resultString = [];

							if (currentMonth.incidents > 0) {
								resultString.push(`Incidents: ${currentMonth.incidents}`);
							}

							//format and sort incidents to push to string
							const incidents = currentMonth.branches
								.map((branch: IMonthBranch) => {
									return {
										name: branch.name,
										incidents: branch.incidents,
									};
								})
								.filter((branch: IIncident) => branch.incidents !== 0)
								.sort(
									(a: IIncident, b: IIncident) => b.incidents - a.incidents
								);

							incidents.forEach((incident: IIncident) =>
								resultString.push(` - ${incident.name}: ${incident.incidents}`)
							);

							if (currentMonth.faults > 0) {
								if (currentMonth.incidents > 0) {
									resultString.push('');
								}

								resultString.push(`Faults: ${currentMonth.faults}`);
							}

							//format and sort faults to push to string
							const faults = currentMonth.branches
								.map((branch: IMonthBranch) => {
									return {
										name: branch.name,
										faults: branch.faults,
									};
								})
								.filter((branch: IFault) => branch.faults !== 0)
								.sort((a: IFault, b: IFault) => b.faults - a.faults);

							faults.forEach((fault: IFault) =>
								resultString.push(` - ${fault.name}: ${fault.faults}`)
							);

							if (currentMonth.surveys > 0) {
								if (currentMonth.faults > 0) {
									resultString.push('');
								}
								resultString.push(`Surveys: ${currentMonth.surveys}`);
							}
							//format and sort faults to push to string
							const surveys = currentMonth.branches
								.map((branch: IMonthBranch) => {
									return {
										name: branch.name,
										surveys: branch.survey_submissions,
									};
								})
								.filter((branch: ISurvey) => branch.surveys !== 0)
								.sort((a: ISurvey, b: ISurvey) => b.surveys - a.surveys);

							surveys.forEach((survey: ISurvey) =>
								resultString.push(` - ${survey.name}: ${survey.surveys}`)
							);

							const total =
								currentMonth.surveys +
								currentMonth.faults +
								currentMonth.incidents;

							if (total > 0) {
								resultString.push('');
								resultString.push(`Total: ${total}`);
							}

							return resultString;
						} else {
							return [`${context[0].dataset.label}: ${context[0].raw}`];
						}
					},
				},
				padding: {
					left: 10,
					right: 30,
					y: 10,
				},
				borderColor: 'rgba(52, 64, 84, 0.5)',
				borderWidth: 1,
				backgroundColor: '#FFFFFF',
				titleColor: '#344054',
				bodyColor: '#344054',
				bodyFont: {
					size: 11,
				},
			},
		},
		responsive: true,
		interaction: {
			mode: 'index' as const,
			intersect: false,
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
	};

	const data = {
		labels: monthData.map((branch: IMonth) => branch.month),
		datasets: [
			{
				label: 'Incidents',
				data: monthData.map((branch: IMonth) => branch.incidents),
				backgroundColor: '#BEBEBE',
				stack: 'Stack 0',
			},
			{
				label: 'Faults',
				data: monthData.map((branch: IMonth) => branch.faults),
				backgroundColor: '#FF6770',
				stack: 'Stack 1',
			},
			{
				label: 'Surveys',
				data: monthData.map((branch: IMonth) => branch.surveys),
				backgroundColor: '#5F6FFF',
				stack: 'Stack 2',
			},
		],
	};

	return <Bar options={options} data={data} />;
};

export default JobBreakdownGroupBarGraph;
