import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const Spinner: React.FC = () => {
	return (
		<TailSpin
			height="100"
			width="100"
			ariaLabel="tail-spin-loading"
			color="#FE9F00"
			wrapperClass="flex justify-center mt-24"
			radius={1}
			visible
		/>
	);
};

export default Spinner;
