import React, { useEffect, useState } from 'react';

import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import { TrashIcon } from '@heroicons/react/outline';

export interface IFault {
	id: number;
	fault_type: string;
	priority: string;
}

export interface IVehicleFault {
	className?: string;
	handleVehicleFaultUpdate: (
		property: string,
		sectionLabel: string,
		index?: number
	) => void;
	id: number;
	deleteFault(id: number): void;
	faultOptions: { id: string; label: string }[];
	index: number;
	fault: IFault;
}

const VehicleFault: React.FC<IVehicleFault> = ({
	handleVehicleFaultUpdate,
	id,
	deleteFault,
	fault,
	index,
	faultOptions,
}) => {
	const [payload, setPayload] = useState(fault);

	useEffect(() => {
		setPayload(fault);
	}, [fault]);

	const priorities = [
		{ name: 'Jack and wrench', level: 'Low' },
		{ name: 'Vehicle damage', level: 'Med' },
		{ name: 'Spare wheel', level: 'Low' },
		{ name: 'Tyre wear', level: 'Med' },
		{ name: 'Roof Arrow', level: 'Med' },
		{ name: 'Tow Bar', level: 'Low' },
		{ name: 'Coolant Leaks', level: 'Med' },
		{ name: 'Fire Extinguisher', level: 'Med' },
		{ name: 'Fully stocked first aid kit in the vehicle', level: 'Med' },
		{ name: 'Cabin/Tray free from litter/rubbish', level: 'Low' },
	];

	const handleOnChange = (property: string, value: string) => {
		handleVehicleFaultUpdate(property, value, index);

		const indexPriority = priorities
			.map((priority) => priority.name.toLowerCase())
			.indexOf(value.toLowerCase());
		const valuePriority =
			indexPriority > -1 ? priorities[indexPriority].level : 'High';
		handleVehicleFaultUpdate('priority', valuePriority, index);
	};

	return (
		<div className="flex flex-row gap-6 items-end pb-4">
			<div>
				<Select
					label="Fault"
					options={faultOptions}
					onChange={(value: string) => handleOnChange('fault_type', value)}
					className="w-[425px]"
					required
					selected={payload.fault_type}
				/>
			</div>
			<div>
				<Input
					type="text"
					value={fault.priority}
					className="w-[425px]"
					required
					label="Priority"
					isDisabled={true}
				/>
			</div>
			<div className="flex items-center">
				<TrashIcon
					height={20}
					width={18}
					className="cursor-pointer text-secondary-50 align-bottom"
					onClick={() => {
						deleteFault(id);
					}}
				/>
			</div>
		</div>
	);
};

export default VehicleFault;
