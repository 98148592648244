import './Button.module.css';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import React, { ReactNode } from 'react';

export interface IButton {
	type?:
		| 'primary'
		| 'secondary'
		| 'tertiary'
		| 'quaternary'
		| 'quinary'
		| 'icon';
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	height?: number | null;
	width?: number | null;
	onClick?: () => void;
	isDisabled?: boolean;
	isSubmit?: boolean;
	navigate?: 'default' | 'next' | 'previous';
	xIcon?: boolean;
	children?: ReactNode;
	className?: string;
	rightClickLink?: string;
}

const Button: React.FC<IButton> = ({
	type = 'primary',
	size = 'md',
	height,
	width,
	navigate = 'default',
	xIcon = false,
	isDisabled = false,
	isSubmit = false,
	className = '',
	onClick,
	children,
	rightClickLink,
}) => {
	const heightStyle = height ? { height } : {};
	const widthStyle = width ? { width } : {};

	const btnSizes = {
		xs: 'px-2 py-1 text-xs',
		sm: 'px-6 py-2 text-sm',
		md: 'px-3 py-2 text-sm',
		lg: 'px-4 py-3 text-sm',
		xl: 'px-8 py-2 text-xl',
	};

	const iconSize = {
		xs: 'h-4 mr-1',
		sm: 'h-5 mr-2',
		md: 'h-5 mr-2',
		lg: 'h-6 mr-3',
		xl: 'h-7 mr-3',
	};

	const buttonDefault = {
		primary:
			'flex justify-center items-center relative p-4 rounded-[5px] bg-primary text-primary-800 border border-primary',
		secondary:
			'flex justify-center items-center relative p-4 rounded-[5px] bg-white text-primary border border-primary',
		tertiary:
			'flex justify-center items-center relative bg-transparent text-primary border-b px-0 border-primary',
		quaternary:
			'flex justify-center items-center relative rounded-[5px] bg-transparent text-primary border border-primary',
		quinary:
			'flex justify-center items-center relative rounded-[5px] bg-transparent text-red border border-red',
		icon: 'flex justify-center items-center relative bg-transparent text-primary px-0 border-primary',
	};

	const buttonHover = {
		primary: 'hover:bg-primary-800 hover:border-primary-800 hover:text-primary',
		secondary: 'hover:border-primary-800 hover:text-primary-800',
		tertiary: 'hover:border-transparent hover:text-primary-800',
		quaternary:
			'hover:bg-primary-800 hover:border-primary-800 hover:text-primary',
		quinary: 'hover:border-primary-800 hover:text-primary-800',
		icon: 'hover:border-transparent hover:text-primary-800',
	};

	const buttonActive = {
		primary: 'active:bg-[#ffd898] active:border-[#ffd898] active:text-primary',
		secondary: 'active:border-[#ffd898] active:text-[#ffd898]',
		tertiary:
			'active:border-transparent active:text-primary-600 active:border-transparent',
		quaternary:
			'active:border-transparent active:text-primary-600 active:border-transparent',
		quinary: 'active:border-red active:text-red',
		icon: 'active:border-transparent active:text-primary-600 active:border-transparent',
	};

	const buttonDisabled = {
		primary:
			'disabled:bg-white disabled:border disabled:border-secondary-50 disabled:text-secondary-50',
		secondary: 'disabled:border-secondary-50 disabled:text-secondary-50',
		tertiary: 'disabled:border-transparent disabled:text-secondary-50',
		quaternary: 'disabled:border-transparent disabled:text-secondary-50',
		quinary: 'disabled:border-transparent disabled:text-secondary-50',
		icon: 'disabled:border-transparent disabled:text-secondary-50',
	};

	const content = (
		<button
			type={isSubmit ? 'submit' : 'button'}
			className={`
			${btnSizes[size]}
			${buttonDefault[type]}
			${buttonHover[type]}
			${buttonActive[type]}
			${buttonDisabled[type]}
			${className}
			`}
			onClick={onClick}
			disabled={isDisabled}
			style={{ ...heightStyle, ...widthStyle }}
		>
			{navigate === 'previous' && (
				<ChevronLeftIcon className={'h-5 w-5 mr-1'} />
			)}
			{xIcon && <XIcon className={iconSize[size]} />}
			{children}
			{navigate === 'next' && <ChevronRightIcon className={'h-5 w-5 ml-1'} />}
		</button>
	);

	if (rightClickLink) {
		return (
			<a
				className={className}
				href={rightClickLink}
				onClick={(e) => e.preventDefault()}
			>
				{content}
			</a>
		);
	}

	return content;
};

export default Button;
