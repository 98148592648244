/**
 * Converts a full name to initials, plus last name:
 * John Doe => J. Doe
 *
 * @param {IOption[]} checkBoxGroup Checkbox Group
 * @return {boolean} if the checkbox group has a checkbox ticked
 */
import { IOption } from 'types/VehicleIncidents';
export const isCheckBoxChecked = (checkBoxGroup: IOption[]) => {
	if (
		checkBoxGroup.some((item) => {
			return item.checked;
		})
	) {
		return true;
	}
	return false;
};
