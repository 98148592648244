import { ReactComponent as VehicleFaultIcon } from 'assets/images/Faults.svg';
import { ReactComponent as IncidentIcon } from 'assets/images/Incidents.svg';
import { ReactComponent as SurveyIcon } from 'assets/images/Surveys.svg';
import Divider from 'components/atoms/Divider';
import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import BranchTotalGraph from 'components/molecules/BranchTotalGraph';
import CurrentYearGraph from 'components/molecules/CurrentYearGraph';
import DashboardCard from 'components/molecules/DashboardCard';
import DashboardIncidentFilters from 'components/molecules/DashboardIncidentFilters';
import DashboardStatusCard from 'components/molecules/DashboardStatusCard';
import DashboardIncidentCategoryBreakdownCard from 'components/molecules/DashboardIncidentCategoryBreakdownCard';
import JobBreakdownGroupBarGraph from 'components/molecules/JobBreakdownGroupBarGraph';
import IncidentCategoryBreakdownBarGraph from 'components/molecules/IncidentCategoryBreakdownBarGraph';
import MainLayout from 'components/templates/MainLayout';
import { useEffect, useState } from 'react';
import { useBranchesByStateMutation } from 'redux/api/branchAPI';
import { useGetBreakdownByCategoryMutation } from 'redux/api/vehicleIncidents';
import { IIncidentCategoryBreakdownFilters } from 'types/VehicleIncidents';

const Dashboard = () => {
	const [getBranchesByState, { data }] = useBranchesByStateMutation();
	const [getBreakdownByCategory, { data: incidentCategoryData }] =
		useGetBreakdownByCategoryMutation();
	const [categoryBreakdownFilters, setCategoryBreakdownFilters] =
		useState<IIncidentCategoryBreakdownFilters>({});

	useEffect(() => {
		getBranchesByState();
	}, [getBranchesByState]);

	useEffect(() => {
		getBreakdownByCategory(categoryBreakdownFilters);
	}, [getBreakdownByCategory, categoryBreakdownFilters]);

	if (!data) {
		return <MainLayout url="dashboard">Loading...</MainLayout>;
	}

	const setFilters = (filters: IIncidentCategoryBreakdownFilters) => {
		setCategoryBreakdownFilters(filters);
	};

	const getFilters = () => {
		return categoryBreakdownFilters;
	};

	return (
		<MainLayout url="dashboard">
			<main>
				<div className="bg-gray-200 p-12">
					<Heading className="" type="h1">
						{data.states.join(', ')}
					</Heading>
					<Text>{data.branches.map((branch) => branch.name).join(', ')}</Text>
					<div className="flex mt-8 space-x-4">
						<DashboardCard className="basis-1/4" title="Current Year">
							<CurrentYearGraph
								incident={data.currentYear.incident.total}
								fault={data.currentYear.vehicleFault.total}
								survey={data.currentYear.surveySubmission.total}
								total={data.currentYear.total}
							/>
						</DashboardCard>
						<DashboardCard className="basis-1/4" title="Branch Totals">
							<BranchTotalGraph branches={data.branches} />
						</DashboardCard>
						<DashboardCard className="basis-1/2" title="Top 5 - Monthly">
							<JobBreakdownGroupBarGraph monthData={data.currentMonthData} />
						</DashboardCard>
					</div>
					<Divider color="border-[#B2B2B2]" className="my-8" />
					<Heading className="my-8" type="h3" colour="secondary-800">
						Branch Breakdown
					</Heading>
					<div className="flex space-x-4">
						<DashboardCard
							className="basis-1/3 max-h-[600px] overflow-y-auto"
							title="Incidents"
							Icon={IncidentIcon}
							total={data.incidentStatusData.total}
							dividerColor="border-grey-200"
						>
							<DashboardStatusCard
								incidentPath="incidents"
								branches={data.incidentStatusData.branches}
							/>
						</DashboardCard>
						<DashboardCard
							className="basis-1/3 max-h-[600px] overflow-y-auto"
							title="Faults"
							Icon={VehicleFaultIcon}
							total={data.faultStatusData.total}
							dividerColor="border-grey-200"
						>
							<DashboardStatusCard
								incidentPath="vehicle-faults"
								branches={data.faultStatusData.branches}
							/>
						</DashboardCard>
						<DashboardCard
							className="basis-1/3 max-h-[600px] overflow-y-auto"
							title="Surveys"
							Icon={SurveyIcon}
							total={data.surveyStatusData.total}
							dividerColor="border-grey-200"
						>
							<DashboardStatusCard
								incidentPath="surveys"
								branches={data.surveyStatusData.branches}
							/>
						</DashboardCard>
					</div>
					<Divider color="border-[#B2B2B2]" className="my-8" />
					<Heading className="my-8" type="h3" colour="secondary-800">
						Incident Category Breakdown
					</Heading>
					<div className="flex space-x-1 mb-10">
						<DashboardIncidentFilters
							filters={categoryBreakdownFilters}
							setFilters={setFilters}
						/>
					</div>
					<div className="min-h-[600px]">
						<div className="flex space-x-4">
							<DashboardCard
								className="basis-1/3 max-h-[600px] overflow-y-auto"
								title="Incident Categories"
								Icon={IncidentIcon}
								total={incidentCategoryData?.total}
								dividerColor="border-grey-200"
							>
								<DashboardIncidentCategoryBreakdownCard
									incidentPath="incidents"
									categories={incidentCategoryData?.categories}
									getExtraFilters={getFilters}
								/>
							</DashboardCard>
							<DashboardCard
								className="basis-2/3 max-h-[600px] min-h-[600px] overflow-y-auto"
								title=""
								Icon={IncidentIcon}
								dividerColor="border-grey-200"
							>
								<IncidentCategoryBreakdownBarGraph
									categoryData={incidentCategoryData?.graphCategories}
								/>
							</DashboardCard>
						</div>
						{!incidentCategoryData && (
							<div className="flex space-x-4">Loading...</div>
						)}
					</div>
				</div>
			</main>
		</MainLayout>
	);
};

export default Dashboard;
