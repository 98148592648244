import Button from 'components/atoms/Button';
import DateInput from 'components/atoms/DateInput';
import Divider from 'components/atoms/Divider';
import Input from 'components/atoms/Input';
import Accordion from 'components/molecules/Accordion';
import CheckBoxGroup from 'components/molecules/CheckBoxGroup';
import Text from 'components/atoms/Text';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetVehicleFaultsMutation } from 'redux/api/vehicleFaults';
import { useAppSelector } from 'redux/hooks';
import {
	resetFilterState,
	updateFilterState,
} from 'redux/slices/vehicleFaultSlice';
import { filtersToQueryStringConverter } from 'utils/filters';

export interface IFaultSearchBar {
	setFilters: React.Dispatch<React.SetStateAction<string>>;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FaultSearchBar: React.FC<IFaultSearchBar> = ({ setFilters, setOpen }) => {
	const dispatch = useDispatch();
	const filters = useAppSelector((state) => state.vehicleFault.filters);
	const [getFaults, { data }] = useGetVehicleFaultsMutation();
	const [filterData, setFilterData] = useState(data?.sortData);

	const onClick = () => {
		const query = filtersToQueryStringConverter(filters);
		setFilters(query);
		setOpen(false);
		dispatch(resetFilterState());
	};

	const onRegistrationChange = (value: React.ChangeEvent<HTMLInputElement>) => {
		const filterState = JSON.parse(JSON.stringify(filters));
		filterState['registration'] = value;
		dispatch(updateFilterState(filterState));
	};

	useEffect(() => {
		getFaults('all');
	}, [getFaults]);

	useEffect(() => {
		setFilterData(data?.sortData);
	}, [data?.sortData]);

	useEffect(() => {
		if (data?.sortData && filterData !== undefined) {
			const branchData = data?.sortData[0]?.branch;

			const filterState = JSON.parse(JSON.stringify(filters));
			filterState['branch'] = branchData;
			dispatch(updateFilterState(filterState));
		}

		//removes dependency for "filters"
		// eslint-disable-next-line
	}, [setFilterData, filterData, dispatch, data?.sortData]);

	return (
		<>
			<Divider />
			<Accordion title="Date Range">
				<DateInput
					inputLabel="From"
					placeholder="From"
					filterDateType="fault"
					iconClassName="text-secondary"
				/>
				<DateInput
					inputLabel="To"
					placeholder="To"
					filterDateType="fault"
					iconClassName="text-secondary"
				/>
			</Accordion>
			<Divider />
			<Accordion title="Branch">
				<CheckBoxGroup filterName="branch" filterType="fault" allCheckBox />
			</Accordion>
			<Divider />
			<Accordion title="Asset ID">
				<Text className="text-sm mt-1 mb-4">
					Enter Registration or Asset Description
				</Text>

				<Input
					type="text"
					onChange={onRegistrationChange}
					value={filters['registration'] as string}
					placeholder="Search"
					size="normal"
					className="mt-1"
				/>
			</Accordion>
			<Divider />
			<Accordion title="Vehicle Type">
				<CheckBoxGroup filterName="vehicle" filterType="fault" allCheckBox />
			</Accordion>
			<Divider />
			<Accordion title="Priority">
				<CheckBoxGroup filterName="priority" filterType="fault" allCheckBox />
			</Accordion>
			<Divider />
			<Accordion title="Status">
				<CheckBoxGroup filterName="status" filterType="fault" allCheckBox />
			</Accordion>
			<Button
				className="h-12 w-[222px] mx-auto my-6 font-bold"
				onClick={onClick}
			>
				Apply
			</Button>
		</>
	);
};

export default FaultSearchBar;
