import { useEffect } from 'react';
import { IIncident, ISection4ErrorStates } from 'types/VehicleIncidents';
import { IVehicleIncidentSection4 } from 'types/VehicleIncidents';
import { isCheckBoxChecked } from 'utils/isCheckBoxChecked';

export const useGetIncidentSection4 = (
	isSuccess: boolean,
	data: IIncident | undefined,
	setIncidentSection4: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection4>
	>,
	setSection4IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection4ErrorStates>
	>,
	incidentSection3Injuries: boolean
) => {
	useEffect(() => {
		if (isSuccess && data) {
			if (isSuccess && data) {
				const {
					severity_rating,
					reported_to,
					reference_number,
					notes,
					notifiable_incident,
					life_saving_rule_breach,
					code_of_conduct_breach,
					incident_category,
					life_saving_rules,
					code_of_conduct,
					incident_mechanism,
					incident_agency,
					incident_mechanism_other_description,
					incident_agency_other_description,
				} = data.incidentData.incident_section_4 ?? {
					severity_rating: null,
					reported_to: '',
					reference_number: '',
					notes: '',
					notifiable_incident: '',
					life_saving_rule_breach: '',
					code_of_conduct_breach: '',
					incident_category: [],
					life_saving_rules: [],
					code_of_conduct: [],
					incident_mechanism: [],
					incident_agency: [],
					incident_mechanism_other_description: null,
					incident_agency_other_description: null,
				};

				setIncidentSection4({
					severityRating: severity_rating,
					incidentCategory: incident_category,
					lifeSavingRules: life_saving_rules,
					codeOfConduct: code_of_conduct,
					reportedTo: reported_to || '',
					referenceNumber: reference_number || '',
					notes: notes || '',
					isNotifiableIncident: notifiable_incident,
					isLifeSavingBreach: life_saving_rule_breach,
					isCodeOfConductBreach: code_of_conduct_breach,
					incidentMechanism: incident_mechanism,
					incidentMechanismOtherInput: incident_mechanism_other_description,
					incidentAgency: incident_agency,
					incidentAgencyOtherInput: incident_agency_other_description,
				});

				setSection4IncidentErrorStates({
					section_4_incident_category: isCheckBoxChecked(incident_category),
					section_4_reported_to: !!reported_to,
					section_4_reference_number: !!reference_number,
					section_4_life_saving_rules: isCheckBoxChecked(life_saving_rules),
					section_4_code_of_conduct_checkbox:
						isCheckBoxChecked(code_of_conduct),
					section_4_incident_mechanism:
						isCheckBoxChecked(incident_mechanism) || incidentSection3Injuries,
					section_4_incident_agency:
						isCheckBoxChecked(incident_agency) || incidentSection3Injuries,
				});
			}
		}
	}, [
		isSuccess,
		data,
		setIncidentSection4,
		setSection4IncidentErrorStates,
		incidentSection3Injuries,
	]);
};
