import { IUserPermissions } from 'types/User';

export const hasAccess = (
	userPermissions: IUserPermissions[] | undefined,
	role: 'staff' | 'manager' | 'admin-manager' | undefined | null,
	accessType?:
		| 'fault_level'
		| 'incident_level'
		| 'survey_level'
		| undefined
		| null,
	accessTypeLevel?: 'read' | 'write' | undefined | null,
	branchId?: string | undefined | null
) => {
	const permission = userPermissions?.find((permission) => {
		if (role) {
			// if role not set, don't even check it
			if (role !== 'manager') {
				// if not manager, do simple comparison
				if (permission.role !== role) return false;
			} else {
				// if manager, check for admin managers too
				if (
					permission.role !== 'manager' &&
					permission.role !== 'admin-manager'
				)
					return false;
			}
		}

		if (accessType && accessTypeLevel) {
			if (accessTypeLevel === 'write') {
				if (permission[accessType] !== 'write') return false;
			} else {
				if (
					permission[accessType] !== 'read' &&
					permission[accessType] !== 'write'
				)
					return false;
			}
		}

		if (branchId) {
			if (permission.branch?.id?.toString() !== branchId.toString())
				return false;
		}

		return true;
	});

	return permission ? true : false;
};
