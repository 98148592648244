import React, { useEffect, useState } from 'react';

export interface IHeading {
	type: 'h1' | 'h2' | 'h3' | 'h4';
	colour?: 'primary' | 'secondary' | 'secondary-800' | 'secondary-300';
	className?: string;
	isCentered?: boolean;
	children?: React.ReactNode;
	bold?: boolean;
}

const elements = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
};

const Heading: React.FC<IHeading> = ({
	type = 'h1',
	children,
	className = '',
	isCentered = false,
	colour = 'secondary',
	bold = true,
	...props
}) => {
	const [style, setStyle] = useState<string>('');

	useEffect(() => {
		const textStyle = {
			h1: `font-inter text-[34px] leading-[40px] tracking-[-1px] text-${
				colour === 'primary' ? 'primary-500' : colour
			}`,
			h2: `font-inter text-[24px] leading-[30px] tracking-[-1px] text-${
				colour === 'primary' ? 'primary-500' : colour
			}`,
			h3: `font-inter text-[18px] leading-[26px] tracking-[-1px] text-${
				colour === 'primary' ? 'primary-500' : colour
			}`,
			h4: `font-inter text-[14px] leading-[22px] tracking-[-1px] text-${
				colour === 'primary' ? 'primary-500' : colour
			}`,
		};

		setStyle(`${textStyle[type]}`);
	}, [type, colour]);

	return React.createElement(
		elements[type],
		{
			className: `${style} ${className} ${isCentered ? 'text-center' : ''} ${
				bold ? 'font-semibold' : ''
			}`,
			...props,
		},
		children
	);
};

export default Heading;
