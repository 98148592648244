import React, { ReactNode } from 'react';

import Divider from 'components/atoms/Divider';
import Text from 'components/atoms/Text';

export interface IDashboardCard {
	children: ReactNode;
	title: string;
	className?: string;
	Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	total?: number;
	dividerColor?: string;
}

const DashboardCard: React.FC<IDashboardCard> = ({
	title,
	children,
	className,
	Icon,
	total = -1,
	dividerColor = 'border-secondary-100',
}) => {
	return (
		<div className={`flex flex-col bg-white rounded-lg ${className}`}>
			<div className="flex flex-row px-6 my-4">
				{Icon && <Icon className="fill-black rounded-md mr-2" />}
				<Text className="text-base" color="text-black" type="bold">
					{title} {total > -1 && `(${total})`}
				</Text>
			</div>

			<Divider color={`${dividerColor}`} />
			<div className="px-6 py-4 flex-[2_2_0%]">{children}</div>
		</div>
	);
};

export default DashboardCard;
