import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
	IFilters,
	initialClientFilterState,
	initialLeadsFilterState,
	initialTrafficControllerFilterState,
	initialSearchFilterState,
} from 'types/Surveys';

export interface VehicleSurveyState {
	commentsOpen: boolean;
	commentSurveyId: number | null;
	surveyUid: string | null;
	surveyBranchId: string | null;
	surveyPanelOpen: boolean;
	surveyReportPanelOpen: boolean;
	surveyType: string;
	clientReportFilter: IFilters;
	leadReportFilter: IFilters;
	trafficControllerReportFilter: IFilters;
	searchFilter: IFilters;
}

export interface CommentPayload {
	commentSurveyId: number | null;
	surveyBranchId: string | null;
}

const initialState: VehicleSurveyState = {
	commentsOpen: false,
	commentSurveyId: null,
	surveyUid: null,
	surveyBranchId: null,
	surveyPanelOpen: false,
	surveyReportPanelOpen: false,
	surveyType: 'All',
	clientReportFilter: initialClientFilterState,
	leadReportFilter: initialLeadsFilterState,
	trafficControllerReportFilter: initialTrafficControllerFilterState,
	searchFilter: initialSearchFilterState,
};

const vehicleSurveySlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		openCommentModal(state, action: PayloadAction<CommentPayload>) {
			const payload = action.payload;
			state.commentSurveyId = payload.commentSurveyId;
			state.surveyBranchId = payload.surveyBranchId;
			state.commentsOpen = true;
		},
		closeCommentModal(state) {
			state.commentSurveyId = null;
			state.surveyBranchId = null;
			state.commentsOpen = false;
		},
		openSurveyModal(state, action: PayloadAction<string>) {
			state.surveyPanelOpen = true;
			state.surveyUid = action.payload;
		},
		closeSurveyModal(state) {
			state.surveyUid = null;
			state.surveyPanelOpen = false;
		},
		openSurveyReportModal(state) {
			state.surveyReportPanelOpen = true;
		},
		closeSurveyReportModal(state) {
			state.surveyReportPanelOpen = false;
		},
		updateSurveyType(state, action: PayloadAction<string>) {
			state.surveyType = action.payload;
		},
		updateClientFilterState(state, action: PayloadAction<IFilters>) {
			state.clientReportFilter = action.payload;
		},
		resetClientFilterState(state) {
			state.clientReportFilter = initialClientFilterState;
		},
		updateLeadFilterState(state, action: PayloadAction<IFilters>) {
			state.leadReportFilter = action.payload;
		},
		resetLeadFilterState(state) {
			state.clientReportFilter = initialLeadsFilterState;
		},
		updateTrafficControllerFilterState(state, action: PayloadAction<IFilters>) {
			state.trafficControllerReportFilter = action.payload;
		},
		resetTrafficControllerFilterState(state) {
			state.trafficControllerReportFilter = initialLeadsFilterState;
		},
		updateSurveySearchFilterState(state, action: PayloadAction<IFilters>) {
			state.searchFilter = action.payload;
		},
		resetSurveySearchFilterState(state) {
			state.searchFilter = initialSearchFilterState;
		},
	},
});

export const {
	openCommentModal,
	closeCommentModal,
	openSurveyModal,
	closeSurveyModal,
	openSurveyReportModal,
	closeSurveyReportModal,
	updateSurveyType,
	updateClientFilterState,
	resetClientFilterState,
	updateLeadFilterState,
	resetLeadFilterState,
	updateTrafficControllerFilterState,
	resetTrafficControllerFilterState,
	updateSurveySearchFilterState,
	resetSurveySearchFilterState,
} = vehicleSurveySlice.actions;
export default vehicleSurveySlice.reducer;
