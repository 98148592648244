import CurrentYearBar from '../CurrentYearBar';
import React from 'react';

export interface ICurrentYearGraph {
	className?: string;
	incident: number;
	fault: number;
	survey: number;
	total: number;
}

const CurrentYearGraph: React.FC<ICurrentYearGraph> = ({
	className,
	incident,
	fault,
	survey,
	total,
}) => {
	return (
		<div className={`flex flex-col space-y-4 ${className}`}>
			<CurrentYearBar
				title="Total"
				value={total}
				total={total}
				backgroundColor="bg-primary"
				textColor="text-primary"
			/>
			<CurrentYearBar
				title="incidents"
				value={incident}
				total={total}
				backgroundColor="bg-gray-500"
				textColor="text-gray-500"
			/>
			<CurrentYearBar
				title="Faults"
				value={fault}
				total={total}
				backgroundColor="bg-red"
				textColor="text-red"
			/>
			<CurrentYearBar
				title="Surveys"
				value={survey}
				total={total}
				backgroundColor="bg-blue"
				textColor="text-blue"
			/>
		</div>
	);
};

export default CurrentYearGraph;
