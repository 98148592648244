import React from 'react';
import StatusButton from 'components/atoms/StatusButton';
import { IBranch } from 'types/Branches';
import { Link } from 'react-router-dom';

export interface IDashboardStatusCard {
	className?: string;
	branches: IBranch[];
	incidentPath?: string;
}

const DashboardStatusCard: React.FC<IDashboardStatusCard> = ({
	className = '',
	branches,
	incidentPath,
}) => {
	var incident_total: JSX.Element | string = '';
	return (
		<table className={`${className} px-8 w-full`}>
			<thead className="border-b border-gray-200">
				<tr>
					<th className="text-secondary font-normal text-left px-4 pb-2">
						Branch
					</th>
					<th className="text-secondary font-normal text-left px-4 pb-2">
						Total
					</th>
					<th className="text-secondary font-normal text-center px-4 pb-2">
						Status
					</th>
				</tr>
			</thead>
			<tbody>
				{branches.map((branch: IBranch) => {
					if (incidentPath === 'incidents') {
						incident_total = (
							<Link to={`/${incidentPath}?branch_name=${branch.name}`}>
								{branch.statistics.total}
							</Link>
						);
					} else {
						incident_total = <>{branch.statistics.total}</>;
					}
					return (
						<tr className="border-b border-gray-200" key={branch.id}>
							<td className="px-4 py-5 font-medium">{branch.name}</td>
							<td className="px-4 py-5 font-medium underline">
								{incident_total}
							</td>
							<td>
								<div className="flex flex-row gap-x-2">
									{branch.statistics.draft > 0 && (
										<StatusButton
											status="draft"
											branch={branch.name}
											incidentPath={incidentPath}
											total={branch.statistics.draft}
											isDashboardStatus
										/>
									)}
									{branch.statistics.submitted > 0 && (
										<StatusButton
											status="submitted"
											branch={branch.name}
											incidentPath={incidentPath}
											total={branch.statistics.submitted}
											isDashboardStatus
										/>
									)}
									{branch.statistics.newCount > 0 && (
										<StatusButton
											status="new"
											branch={branch.name}
											total={branch.statistics.newCount}
											isDashboardStatus
										/>
									)}
									{branch.statistics.open > 0 && (
										<StatusButton
											status="open"
											branch={branch.name}
											incidentPath={incidentPath}
											isDashboardStatus
											total={branch.statistics.open}
										/>
									)}
									{branch.statistics.closed > 0 && (
										<StatusButton
											status="closed"
											branch={branch.name}
											incidentPath={incidentPath}
											isDashboardStatus
											total={branch.statistics.closed}
										/>
									)}
								</div>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default DashboardStatusCard;
