import Text from 'components/atoms/Text';
import React, { useEffect, useState } from 'react';

export interface IOption {
	id: number;
	title: string;
	checked: boolean;
	description?: string;
}

export interface ICheckBox {
	isDisabled?: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	className?: string;
	descriptionClassName?: string;
	descriptionDirection?: 'flex-row' | 'flex-col';
	item: IOption;
	onBlur?: () => void;
}

const CheckBox: React.FC<ICheckBox> = ({
	isDisabled = false,
	onChange,
	className = '',
	descriptionClassName = '',
	item,
	descriptionDirection = '',
	onBlur,
}) => {
	const [checked, setChecked] = useState(item.checked);

	useEffect(() => {
		setChecked(item.checked);
	}, [item.checked]);

	return (
		<div className={`flex ${descriptionDirection} mt-2`}>
			<div className="flex flex-row text-sm">
				<input
					id={`${item.id}`}
					aria-describedby={`${item.title}-checkbox`}
					name={`${item.title}`}
					type="checkbox"
					checked={checked}
					disabled={isDisabled}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						onChange(e);
						setChecked(e.target.checked);
					}}
					onBlur={onBlur}
					className="focus:ring-primary-500 h-[18px] w-[18px] text-primary-500 rounded bg-white mr-4 border-gray-300 my-auto"
				/>
				<Text className="flex flex-1 m-auto capitalize">{item.title}</Text>
			</div>
			{item.description && (
				<Text
					className={`${descriptionClassName} text-secondary-300 text-xs my-3`}
				>
					{item.description}
				</Text>
			)}
		</div>
	);
};

export default CheckBox;
