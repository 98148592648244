interface IPill {
	title: string;
	value: string;
	className?: string;
}
export const Pill: React.FC<IPill> = ({ title, value, className }) => {
	return (
		<div
			className={`p-2 text-sm border rounded border-primary-500 ${className}`}
		>
			<span className="text-primary-500">{title}</span>{' '}
			<span className="text-secondary-500">{value}</span>
		</div>
	);
};
