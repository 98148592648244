import Heading from 'components/atoms/Heading';
import SlideDown from 'components/atoms/SlideDown';
import Text from 'components/atoms/Text';
import ToggleButton from 'components/atoms/ToggleButton';
import FormCheckBoxGroup from 'components/molecules/FormCheckBoxGroup';
import Button from 'components/atoms/Button';
import React, { useEffect, useState } from 'react';
import {
	useGetIncidentSection3Mutation,
	useUpdateIncidentSection3Mutation,
} from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';
import {
	IOption,
	ISection3ErrorStates,
	IVehicleIncidentSection3,
	IVehicleIncidentSection3Payload,
	IQueryReportSection,
} from 'types/VehicleIncidents';
import { disableWriteAccess } from 'utils/disableWriteAccess';
import { isCheckBoxChecked } from 'utils/isCheckBoxChecked';

export interface IIncidentSection3 {
	sectionId: number | null;
	incidentSection3: IVehicleIncidentSection3;
	setIncidentSection3: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection3>
	>;
	setSection3IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection3ErrorStates>
	>;
	setSection: React.Dispatch<React.SetStateAction<IQueryReportSection>>;
}

export type ISection3Type =
	| 'bodyPartsInjuredOtherInput'
	| 'injuryTypeOtherInput'
	| 'incidentMechanismOtherInput'
	| 'incidentAgencyOtherInput'
	| 'injuryType'
	| 'bodyParts'
	| 'bodyPartsInjured'
	| 'incidentMechanism'
	| 'incidentAgency';

const IncidentSection3: React.FC<IIncidentSection3> = ({
	sectionId,
	incidentSection3,
	setIncidentSection3,
	setSection3IncidentErrorStates,
	setSection,
}) => {
	const [section3, setSection3] =
		useState<IVehicleIncidentSection3>(incidentSection3);
	const [isInjury, setIsInjury] = useState(section3.injuries);
	const [getIncidentSection3, { data, isSuccess }] =
		useGetIncidentSection3Mutation();
	const [updateIncidentSection3] = useUpdateIncidentSection3Mutation();
	const incidentBranchId = useAppSelector(
		(state) => state.vehicleIncident.incidentBranchId
	);
	const userPermission = useAppSelector((state) => state.user.permissions);
	const disableIncidentWriteAccess = disableWriteAccess(
		'incident_level',
		userPermission,
		incidentBranchId
	);

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection3(sectionId);
		}
	}, [getIncidentSection3, sectionId]);

	useEffect(() => {
		setSection3(section3);
	}, [section3]);

	useEffect(() => {
		setIncidentSection3((prevState) => {
			return {
				...prevState,
				bodyParts: section3.bodyParts,
				bodyPartsInjured: section3.bodyPartsInjured,
				injuryType: section3.injuryType,
				incidentMechanism: section3.incidentMechanism,
				incidentAgency: section3.incidentAgency,
				injuries: isInjury,
			};
		});
	}, [
		setIncidentSection3,
		section3.bodyParts,
		section3.bodyPartsInjured,
		section3.injuryType,
		section3.incidentMechanism,
		section3.incidentAgency,
		isInjury,
	]);

	useEffect(() => {
		setIsInjury(section3.injuries);
	}, [section3.injuries]);

	useEffect(() => {
		if (isSuccess && data) {
			const {
				injuries,
				body_injured,
				injury_type,
				incident_mechanism,
				incident_agency,
				injury_type_other_description,
				incident_mechanism_other_description,
				incident_agency_other_description,
				body_injured_other_description,
			} = data.data;

			const bodyPartInjuredArray: IOption[] = [];
			const bodyPartInjuredArray2 = [...body_injured];
			const arrayToFilter = [
				'Left',
				'Right',
				'Upper',
				'Lower',
				'Psych',
				'Internal Organs',
			];

			for (let i = body_injured.length - 1; i >= 0; i--) {
				if (arrayToFilter.includes(body_injured[i].title)) {
					bodyPartInjuredArray.push(body_injured[i]);
					bodyPartInjuredArray2.splice(i, 1);
				}
			}
			bodyPartInjuredArray.sort((a, b) => a.title.localeCompare(b.title));
			bodyPartInjuredArray2.sort((a, b) =>
				a.title === 'Other'
					? 1
					: b.title === 'Other'
					? -1
					: a.title.localeCompare(b.title)
			);

			const injuryTypeArray: IOption[] = [...injury_type].sort((a, b) =>
				a.title === 'Other'
					? 1
					: b.title === 'Other'
					? -1
					: a.title.localeCompare(b.title)
			);
			const incidentMechanismArray: IOption[] = [...incident_mechanism].sort(
				(a, b) =>
					a.title === 'Other'
						? 1
						: b.title === 'Other'
						? -1
						: a.title.localeCompare(b.title)
			);
			const incidentAgencyArray: IOption[] = [...incident_agency].sort((a, b) =>
				a.title === 'Other'
					? 1
					: b.title === 'Other'
					? -1
					: a.title.localeCompare(b.title)
			);

			setSection3({
				...section3,
				bodyParts: bodyPartInjuredArray,
				bodyPartsInjured: bodyPartInjuredArray2,
				bodyPartsInjuredOtherInput: body_injured_other_description,
				injuryType: injuryTypeArray,
				injuryTypeOtherInput: injury_type_other_description,
				incidentMechanism: incidentMechanismArray,
				incidentMechanismOtherInput: incident_mechanism_other_description,
				incidentAgency: incidentAgencyArray,
				incidentAgencyOtherInput: incident_agency_other_description,
				injuries,
			});
			setIsInjury(injuries);
		}
		//removes dependency for "section 3"
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleSection3Update = (
		property: string,
		value: string | IOption[]
	) => {
		const newSection3 = section3;
		if (
			property === 'bodyPartsInjuredOtherInput' ||
			property === 'incidentAgencyOtherInput' ||
			property === 'injuryTypeOtherInput' ||
			property === 'incidentMechanismOtherInput'
		) {
			if (typeof value === 'string') {
				newSection3[property] = value;
				setIncidentSection3(newSection3);
			}
		} else if (
			property === 'bodyPartsInjured' ||
			property === 'bodyParts' ||
			property === 'incidentAgency' ||
			property === 'incidentMechanism' ||
			property === 'injuryType'
		) {
			setSection3({
				...section3,
				[property]: value,
			});
		}
	};

	const handleOnBlur = async () => {
		if (!sectionId || disableIncidentWriteAccess) {
			return;
		}

		const bodyPartsCheckBoxGroup = section3.bodyParts as IOption[];
		const bodyPartsInjuredCheckBoxGroup =
			section3.bodyPartsInjured as IOption[];
		const bodyInjuredCheckboxGroup = [
			...bodyPartsCheckBoxGroup,
			...bodyPartsInjuredCheckBoxGroup,
		];
		const injuryTypeCheckBoxGroup = section3.injuryType as IOption[];
		const incidentMechanismCheckBoxGroup =
			section3.incidentMechanism as IOption[];
		const incidentAgencyCheckBoxGroup = section3.incidentAgency as IOption[];

		const payload: IVehicleIncidentSection3Payload = {
			id: sectionId,
			injuries: isInjury,
			body_injured: bodyInjuredCheckboxGroup,
			injury_type: injuryTypeCheckBoxGroup,
			incident_mechanism: incidentMechanismCheckBoxGroup,
			incident_agency: incidentAgencyCheckBoxGroup,
			injury_type_other_description: section3.injuryTypeOtherInput,
			incident_mechanism_other_description:
				section3.incidentMechanismOtherInput,
			incident_agency_other_description: section3.incidentAgencyOtherInput,
			body_injured_other_description: section3.bodyPartsInjuredOtherInput,
		};

		await updateIncidentSection3(payload);

		setSection3IncidentErrorStates({
			section_3_body_parts: isCheckBoxChecked(bodyInjuredCheckboxGroup),
			section_3_injury_type: isCheckBoxChecked(injuryTypeCheckBoxGroup),
			section_3_incident_mechanism: isCheckBoxChecked(
				incidentMechanismCheckBoxGroup
			),
			section_3_incident_agency: isCheckBoxChecked(incidentAgencyCheckBoxGroup),
		});
	};

	return (
		<>
			<Heading type="h2">Injury / Incident Details</Heading>

			<ToggleButton
				toggle={isInjury}
				setToggle={setIsInjury}
				label="Were there any injuries?"
				isDisabled={disableIncidentWriteAccess}
				onBlur={handleOnBlur}
			/>

			<SlideDown open={isInjury} className={`${isInjury ? 'my-2' : 'hidden'}`}>
				<Text
					className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5"
					type="bold"
				>
					Part of Body Injured *
				</Text>

				<FormCheckBoxGroup
					checkBoxName="bodyParts"
					checkBoxGroupData={section3.bodyParts}
					onHandleChange={handleSection3Update}
					gridColumns={'8'}
					onBlur={handleOnBlur}
					isDisabled={disableIncidentWriteAccess}
				/>
				<div className="h-0 border-t border-primary-200 w-full my-5" />
				<FormCheckBoxGroup
					checkBoxName="bodyPartsInjured"
					checkBoxGroupData={section3.bodyPartsInjured}
					onHandleChange={handleSection3Update}
					gridColumns={'8'}
					otherTextBox={true}
					otherTextBoxValue={section3.bodyPartsInjuredOtherInput}
					otherTextBoxOnChange={(value: string) =>
						handleSection3Update('bodyPartsInjuredOtherInput', value)
					}
					onBlur={handleOnBlur}
					isDisabled={disableIncidentWriteAccess}
				/>

				<div className="h-0 border-t border-primary-200 w-full my-5" />
				<Text
					className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5"
					type="bold"
				>
					Injury Type *
				</Text>
				<FormCheckBoxGroup
					checkBoxName="injuryType"
					checkBoxGroupData={section3.injuryType}
					onHandleChange={handleSection3Update}
					gridColumns={'8'}
					otherTextBox={true}
					otherTextBoxValue={section3.injuryTypeOtherInput}
					otherTextBoxOnChange={(value: string) =>
						handleSection3Update('injuryTypeOtherInput', value)
					}
					onBlur={handleOnBlur}
					otherTextBoxClassName="w-[400px] lg:-ml-24"
					isDisabled={disableIncidentWriteAccess}
				/>
				<div className="h-0 border-t border-primary-200 w-full my-5" />
				<Text
					className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5"
					type="bold"
				>
					Incident Mechanism *
				</Text>
				<FormCheckBoxGroup
					checkBoxName="incidentMechanism"
					checkBoxGroupData={section3.incidentMechanism}
					onHandleChange={handleSection3Update}
					gridColumns={'5'}
					otherTextBox={true}
					otherTextBoxValue={section3.incidentMechanismOtherInput}
					otherTextBoxOnChange={(value: string) =>
						handleSection3Update('incidentMechanismOtherInput', value)
					}
					onBlur={handleOnBlur}
					otherTextBoxClassName="w-[400px] xl:-ml-44"
					isDisabled={disableIncidentWriteAccess}
				/>

				<div className="h-0 border-t border-primary-200 w-full my-5" />
				<Text
					className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5"
					type="bold"
				>
					Incident Agency *
				</Text>
				<FormCheckBoxGroup
					checkBoxName="incidentAgency"
					checkBoxGroupData={section3.incidentAgency}
					onHandleChange={handleSection3Update}
					gridColumns={'5'}
					otherTextBox={true}
					otherTextBoxValue={section3.incidentAgencyOtherInput}
					otherTextBoxOnChange={(value: string) =>
						handleSection3Update('incidentAgencyOtherInput', value)
					}
					onBlur={handleOnBlur}
					otherTextBoxClassName="w-[400px] lg:-ml-44 rounded-lg"
					isDisabled={disableIncidentWriteAccess}
				/>
			</SlideDown>
			<div className="h-0 border-t-[1px] border-primary-200 w-full my-5" />
			<div className="flex justify-end">
				<Button
					onClick={() => setSection('section2')}
					type="primary"
					className="font-bold mr-4"
				>
					Back to Person Involved
				</Button>
				<Button
					onClick={() => setSection('section4')}
					type="primary"
					className="font-bold mr-4"
				>
					Continue to Analysis
				</Button>
			</div>
		</>
	);
};

export default IncidentSection3;
