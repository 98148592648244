import Heading from 'components/atoms/Heading';
import { useEffect, useState } from 'react';
import { useUnsubscribeUserMutation } from 'redux/api/unsubscribeAPI';
import { useQuery } from 'redux/hooks';
import { useDisplayErrorMessage } from 'utils/useDisplayErrorMessage';

const Unsubscribe = () => {
	const query = useQuery();
	const [message, setMessage] = useState('');
	const [unsubscribeUser, { isLoading, isSuccess, isError, error }] =
		useUnsubscribeUserMutation();

	useEffect(() => {
		const email = query.get('email');
		const uid = query.get('uid');
		if (!email) {
			setMessage('Error: email not provided');
		} else if (!uid) {
			setMessage('Error: Could not unsubscribe user');
		} else {
			unsubscribeUser({ email, uid });
		}
	}, [unsubscribeUser, query]);

	useEffect(() => {
		if (isLoading) {
			setMessage('Unsubscribing...');
		}
	}, [isLoading]);

	useEffect(() => {
		if (isSuccess) {
			setMessage('User Unsubscribed successfully');
		}
	}, [isSuccess]);

	useDisplayErrorMessage(isError, error, setMessage);

	return (
		<main className="flex flex-col grow items-center justify-center h-screen">
			<div className="flex flex-col items-center bg-white w-full">
				<Heading type="h1">
					{message} {isLoading}
				</Heading>
			</div>
		</main>
	);
};

export default Unsubscribe;
