import { EyeIcon, ArrowRightIcon } from '@heroicons/react/outline';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Radio from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import React, { useState } from 'react';
import { useAssignFaultMutation } from 'redux/api/vehicleFaults';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
	openCommentModal,
	openActionsModal,
} from 'redux/slices/vehicleFaultSlice';
import { IVehicleFault } from 'types/VehicleFaults';

export interface IVehicleCard {
	fault: IVehicleFault;
	refetchData: () => void;
}

const VehicleCard: React.FC<IVehicleCard> = ({ fault, refetchData }) => {
	const [assignFault] = useAssignFaultMutation();
	const [status, setStatus] = useState(fault.status);
	const userPermission = useAppSelector((state) => state.user.permissions);
	const dispatch = useAppDispatch();

	const hasWriteAccess = () => {
		const permission = userPermission?.filter(
			(permission) =>
				permission.branch?.id === fault.branch?.id &&
				permission.fault_level === 'write'
		);

		if (!permission) {
			return false;
		}

		return permission.length > 0;
	};

	const handleOpenComments = () => {
		const payload = {
			commentFaultId: fault.id,
			faultBranchId: fault?.branch?.id,
		};
		dispatch(openCommentModal(payload));
	};

	const handleOpenActions = () => {
		dispatch(openActionsModal(fault));
	};

	const handleUpdateFault = async () => {
		await assignFault({
			id: fault.id,
			body: {
				status,
				ticket_number: fault?.ticket_number,
			},
		});

		refetchData();
	};

	return (
		<div className="w-full px-10 pt-8 pb-10 border-t border-primary">
			<div className="flex">
				<div className="flex-col w-3/4">
					<Heading type="h2" className="mb-6">
						Overview
					</Heading>
					<div className="flex justify-between pr-8 space-x-8 lg:pr-12 2xl:pr-32 lg:space-x-12 2xl:space-x-32">
						<div className="min-w-[200px]">
							<Text className="max-w-sm mb-2" type="bold">
								Description
							</Text>
							<Text className="max-w-sm mb-10 text-sm">
								{fault.fault_description}
							</Text>
						</div>
						<div className="flex-col w-64">
							<div className="max-h-[300px] overflow-auto pr-5 relative">
								<div className="flex font-semibold">
									<Text type="bold" className="flex mr-4 basis-2/3">
										Fault
									</Text>
									<Text type="bold" className="flex basis-1/3">
										Priority
									</Text>
								</div>
								{fault?.vehicle_faults.map((item) => (
									<div key={`${item.id}`}>
										<div className="flex py-4">
											<Text className="flex mr-4 text-sm basis-2/3" type="bold">
												{item.fault_type}
											</Text>
											<Text className="flex text-sm basis-1/3">
												{item.priority}
											</Text>
										</div>
										<div className="h-0 border-t-[1px] border-primary-200 w-full" />
									</div>
								))}
							</div>
						</div>
						<div className="hidden lg:flex lg:flex-col">
							<div className="mb-4 space-y-2">
								<Text type="bold">Created:</Text>
								<Text className="text-sm">
									{new Date(fault.submitted_at).toLocaleDateString('en-AU')}
								</Text>
							</div>
							<div className="mb-4 space-y-2">
								<Text type="bold">Last Action:</Text>
								{fault?.vehicle_fault_activities ? (
									<>
										<Text className="text-sm">
											Name: {fault.vehicle_fault_activities.name}
										</Text>
										<Text className="text-sm">
											Type: {fault.vehicle_fault_activities.type}
										</Text>
										<Text className="text-sm">
											Date:{' '}
											{new Date(
												fault.vehicle_fault_activities.createdAt
											).toLocaleDateString('en-AU')}
										</Text>
									</>
								) : (
									<Text className="text-sm">No Actions</Text>
								)}
							</div>
							<div className="mb-4 space-y-2">
								<Text type="bold">Assigned to:</Text>
								{fault?.assignees ? (
									fault?.assignees?.map((assignee) => (
										<Text key={assignee.id} className="text-sm">
											{assignee.label}
										</Text>
									))
								) : (
									<Text className="text-sm">Branch: {fault?.branch?.name}</Text>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className="flex justify-end flex-1 w-1/4">
					<div className="w-full max-w-sm">
						<Text className="mb-5" type="bold">
							History
						</Text>
						<div className="flex flex-row justify-around items-start">
							<div className="space-y-5 w-1/2 mr-4">
								<Button
									className="font-bold w-full xl:mb-0"
									onClick={handleOpenComments}
								>
									<EyeIcon className="w-5 h-5 mr-1" />
									View History
								</Button>
							</div>
							<div className="space-y-5 w-1/2 mr-4">
								<Button
									className="font-bold w-full xl:mb-0"
									onClick={handleOpenActions}
								>
									<ArrowRightIcon className="w-5 h-5 mr-1" />
									Action
								</Button>
							</div>
						</div>
						{hasWriteAccess() && (
							<>
								<div className="h-0 border-t-[1px] border-primary-200 w-full my-5" />
								<Text type="bold" className="mt-4 mb-3">
									Status
								</Text>
								<Radio
									className="mb-7"
									classNameWrapper="space-x-4"
									options={[
										{ title: 'Open', id: 'open' },
										{ title: 'Closed', id: 'closed' },
									]}
									radioDirection="flex-row"
									defaultId={status}
									onChange={setStatus}
								/>
								<Button
									navigate="next"
									onClick={handleUpdateFault}
									className="font-bold"
								>
									Submit
								</Button>
							</>
						)}
					</div>
				</div>
			</div>

			<div className="flex justify-between w-full mt-8 lg:hidden">
				<div className="mb-4 space-y-2">
					<Text type="bold">Created:</Text>
					<Text className="text-sm">
						{new Date(fault.submitted_at).toLocaleDateString('en-AU')}
					</Text>
				</div>
				<div className="mb-4 space-y-2">
					<Text type="bold">Last Action:</Text>
					{fault?.vehicle_fault_activities ? (
						<>
							<Text className="text-sm">
								Name: {fault.vehicle_fault_activities.name}
							</Text>
							<Text className="text-sm">
								Type: {fault.vehicle_fault_activities.type}
							</Text>
							<Text className="text-sm">
								Date:{' '}
								{new Date(
									fault.vehicle_fault_activities.createdAt
								).toLocaleDateString('en-AU')}
							</Text>
						</>
					) : (
						<Text className="text-sm">No Actions</Text>
					)}
				</div>

				<div className="mb-4 space-y-2">
					<Text type="bold">Assigned to:</Text>
					{fault?.assignees ? (
						fault?.assignees?.map((assignee) => (
							<Text key={assignee.id} className="text-sm">
								{assignee.label}
							</Text>
						))
					) : (
						<Text className="text-sm">Branch: {fault?.branch?.name}</Text>
					)}
				</div>
			</div>
		</div>
	);
};

export default VehicleCard;
