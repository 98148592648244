import Heading from 'components/atoms/Heading';
import SurveyResponsesPieChartCard from 'components/molecules/SurveyResponsesPieChartCard';
import SurveyResponsesTable from 'components/molecules/SurveyResponsesTable';
import SurveyResponsesTableCard from 'components/molecules/SurveyResponsesTableCard';
import React from 'react';
import { useGetSurveyResponsesReportQuery } from 'redux/api/reportsAPI';
import { IFilters } from 'types/Surveys';
import { isEmptyObject } from 'utils/isEmptyObject';

export interface ISurveyResponsesSection {
	chartColors: string[];
	type: string;
	filter: IFilters;
}

const SurveyResponsesSection: React.FC<ISurveyResponsesSection> = ({
	chartColors,
	type,
	filter,
}) => {
	const getFilterQuery = () => {
		return `${Object.keys(filter)
			.filter((key) => filter[key] && filter[key]?.length)
			.map((key) => `${key}=${filter[key]}`)
			.join('&')}&type=${type}`;
	};

	const { data } = useGetSurveyResponsesReportQuery(getFilterQuery());

	return (
		<div className="flex flex-col mt-8 space-y-8">
			<>
				{data?.statistic && !isEmptyObject(data.statistic) && (
					<div className="flex p-8 space-x-8 bg-white rounded-lg w-fit">
						{data?.statistic.satisfaction && (
							<div className="flex flex-col">
								<Heading type="h2">{data?.statistic.satisfaction} %</Heading>
								<span className="font-thin">Satisfaction</span>
							</div>
						)}
						{data?.statistic.loyalty && (
							<div className="flex flex-col">
								<Heading type="h2">{data?.statistic.loyalty} %</Heading>
								<span className="font-thin">Loyalty</span>
							</div>
						)}
						{data?.statistic.growth && (
							<div className="flex flex-col">
								<Heading type="h2">{data?.statistic.growth} %</Heading>
								<span className="font-thin">Growth</span>
							</div>
						)}
					</div>
				)}

				{data?.charts.map((chart) => (
					<SurveyResponsesPieChartCard
						key={chart.id}
						chartColors={chartColors}
						question={chart.question}
						answers={chart.answers}
						total={chart.total}
					/>
				))}

				{data?.unsubscribed && (
					<div className="flex flex-col w-full p-8 space-y-2 bg-white rounded-lg text-secondary-500">
						<p className="font-extralight ">Unsubscribes</p>
						<Heading type="h2">{data.unsubscribed.length}</Heading>

						<div className="flex">
							<SurveyResponsesTable
								data={data.unsubscribed}
								mapper={[
									{
										heading: 'Recipicant',
										render: (prop) => `${prop.first_name} ${prop.last_name}`,
									},
									{
										heading: 'email',
										render: (prop) => prop.email,
									},
									{
										heading: 'Sent',
										render: (prop) =>
											new Date(prop.createdAt).toLocaleDateString(),
									},
									{
										heading: 'Responded',
										render: (prop) => (prop.responded ? 'Yes' : 'No'),
									},
									{
										heading: 'Unsubscribed',
										render: (prop) =>
											new Date(prop.unsubscribedAt).toLocaleDateString(),
									},
								]}
							/>
						</div>
					</div>
				)}

				{data?.tables.map((table) => (
					<SurveyResponsesTableCard
						key={table.id}
						id={table.id}
						isComment={table.comment}
						isSuggestion={table.suggestion}
						question={table.question}
						surveyAnswers={table.survey_answers}
						total={table.survey_answers.length}
					/>
				))}
			</>
		</div>
	);
};

export default SurveyResponsesSection;
