import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import styles from './surveyResponsesMonthlyTableCell.module.css';
import { v4 as uuidv4 } from 'uuid';

export interface ISurveyResponsesMonthlyTableCell {
	data: string[];
}

const SurveyResponsesMonthlyTableCell: React.FC<
	ISurveyResponsesMonthlyTableCell
> = ({ data }) => {
	const tooltipId = uuidv4();

	return (
		<>
			<td className="text-sm text-gray-900 px-6 py-4">
				<div
					className="w-8 float-right text-right font-semibold hover:text-primary hover:cursor-pointer underline"
					data-tooltip-id={tooltipId}
				>
					{data.length}
				</div>
				<div className={styles.tooltipContainer}>
					{data.length > 0 && (
						<Tooltip
							id={tooltipId}
							className={styles.tooltipItem}
							place="left"
							clickable={true}
						>
							<div>
								<ul>
									{data.map((listItem: string) => (
										<li key={listItem}>{listItem}</li>
									))}
								</ul>
							</div>
						</Tooltip>
					)}
				</div>
			</td>
		</>
	);
};

export default SurveyResponsesMonthlyTableCell;
