import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useMicrosoftLoginMutation } from 'redux/api/authAPI';
import { useQuery } from 'redux/hooks';

const MicrosoftRedirect = () => {
	const query = useQuery();
	const [microsoftLogin, { data, isError, error, isSuccess }] =
		useMicrosoftLoginMutation();
	const [redirect, setRedirect] = useState(false);
	const [loginError, setError] = useState('');
	const token = localStorage.getItem('accessToken');

	useEffect(() => {
		const accessToken = query.get('access_token');
		if (accessToken) {
			microsoftLogin(accessToken);
		} else {
			setError('Error: Access Token Required');
		}
	}, [query, microsoftLogin]);

	useEffect(() => {
		if (isSuccess && data) {
			localStorage.setItem('accessToken', data.jwt);
			setRedirect(true);
		}
	}, [isSuccess, data, setRedirect]);

	useEffect(() => {
		if (isError && error) {
			setError(`Error: ${JSON.stringify(error)}`);
		}
	}, [isError, error]);

	const backToLogin = () => {
		setRedirect(true);
	};

	if (redirect || token) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<main className="flex flex-col grow items-center justify-center h-screen">
			<div className="flex flex-col items-center bg-white w-full">
				<Heading className="text-center" type="h1">
					{loginError}
				</Heading>
				{loginError && (
					<Button
						navigate="previous"
						className="h-[56px] mt-5 font-bold"
						onClick={backToLogin}
					>
						Back to Login
					</Button>
				)}
			</div>
		</main>
	);
};

export default MicrosoftRedirect;
