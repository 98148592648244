import { ClockIcon, PlusIcon } from '@heroicons/react/outline';
import Button from 'components/atoms/Button';
import ComboBox from 'components/atoms/ComboBox';
import DateInput from 'components/atoms/DateInput';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import TextareaInput from 'components/atoms/TextareaInput';
import ToggleButton from 'components/atoms/ToggleButton';
import {
	ISection1Type,
	IVehicleIncidentSection1,
	IVehicleIncidentSection1Payload,
	ISection1ErrorStates,
	IQueryReportSection,
} from 'types/VehicleIncidents';
import ThirdParty from 'components/molecules/ThirdParty';
import React, { useEffect, useState } from 'react';
import {
	useGetIncidentSection1Mutation,
	useUpdateIncidentSection1Mutation,
} from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';
import { disableWriteAccess } from 'utils/disableWriteAccess';
import { removeSeconds } from 'utils/removeSeconds';

export interface IIncidentSection1 {
	sectionId: number | null;
	incidentSection1: IVehicleIncidentSection1;
	setIncidentSection1: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection1>
	>;
	setSection1IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection1ErrorStates>
	>;
	setSection: React.Dispatch<React.SetStateAction<IQueryReportSection>>;
}

interface IThirdParty {
	id: number;
	thirdParty_name: string;
	thirdParty_vehicle_registration: string;
	thirdParty_licence_number: string;
	thirdParty_phone_number: string;
}

const IncidentSection1: React.FC<IIncidentSection1> = ({
	sectionId,
	incidentSection1,
	setIncidentSection1,
	setSection1IncidentErrorStates,
	setSection,
}) => {
	const [updateIncidentSection1] = useUpdateIncidentSection1Mutation();
	const [getIncidentSection1, { data, isLoading, isSuccess }] =
		useGetIncidentSection1Mutation();
	const IncidentState = useAppSelector((state) => state.vehicleIncident);
	const userPermission = useAppSelector((state) => state.user.permissions);
	const [section1, setSection1] =
		useState<IVehicleIncidentSection1>(incidentSection1);
	const [toggle, setToggle] = useState(section1.mv_incident);
	const [thirdPartyToggle, setThirdPartyToggle] = useState(section1.thirdParty);
	const disableIncidentWriteAccess = IncidentState.isNewIncident
		? false
		: disableWriteAccess(
				'incident_level',
				userPermission,
				IncidentState.incidentBranchId
		  );
	const dedupeBranches: { [key: string]: boolean } = {};
	const branchOptions = userPermission
		? userPermission
				.map((permission) => {
					return {
						id: permission.branch.id.toString(),
						label: permission.branch.name,
					};
				})
				.sort((a, b) => {
					return a.label > b.label ? 1 : -1;
				})
				.filter((item) => {
					// @ts-ignore
					return dedupeBranches.hasOwnProperty(item.id)
						? false
						: (dedupeBranches[item.id] = true);
				})
		: [
				{
					id: '',
					label: '',
				},
		  ];

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection1(sectionId);
		}
		//disables dependency for "section 1" and IncidentState.isNewIncident
		// eslint-disable-next-line
	}, [getIncidentSection1, sectionId]);

	const handleSection1Update = (
		property: ISection1Type,
		value: Date | string,
		index?: number
	) => {
		const newSection1: IVehicleIncidentSection1 = section1;
		const newThirdParties = [...(newSection1.thirdParties || [])];

		if (property === 'incident_date' || property === 'reported_date') {
			if (typeof value === 'object') {
				newSection1[property] = value;
			}
		} else if (
			property === 'thirdParty_name' ||
			property === 'thirdParty_vehicle_registration' ||
			property === 'thirdParty_licence_number' ||
			property === 'thirdParty_phone_number'
		) {
			if (typeof index !== 'undefined') {
				newThirdParties[index] = {
					...newThirdParties[index],
					[property]: value,
				};
			}
		} else {
			if (typeof value === 'string') {
				newSection1[property] = value;
			}
		}

		newSection1.thirdParties = newThirdParties;
		setIncidentSection1(newSection1);
	};

	useEffect(() => {
		setSection1(section1);
	}, [section1]);

	useEffect(() => {
		setToggle(section1.mv_incident);
	}, [section1.mv_incident]);

	useEffect(() => {
		if (isSuccess && data) {
			const {
				branch,
				incident_date,
				reported_date,
				incident_time,
				mv_incident,
				incident_description,
				location,
				client_name,
				job_details,
				altusRegistration,
				driverName,
				licenseClass,
				licenseExpiry,
				thirdParty,
				thirdParties,
			} = data?.data;

			const formatIncidentDate = incident_date
				? new Date(incident_date)
				: new Date();
			const formatReportedDate = reported_date
				? new Date(reported_date)
				: new Date();

			const formatTime = removeSeconds(incident_time);

			const formatThirdParty = thirdParties?.map((thirdParty: IThirdParty) => {
				return {
					...thirdParty,
				};
			});

			setSection1({
				...section1,
				description: incident_description || '',
				location: location || '',
				client: client_name || '',
				jobDetails: job_details || '',
				altusRegistration: altusRegistration || '',
				driverName: driverName || '',
				licenseClass: licenseClass || '',
				licenseExpiry: licenseExpiry || '',
				branch: branch || '',
				incident_date: formatIncidentDate,
				reported_date: formatReportedDate,
				incident_time: formatTime || '',
				thirdParty,
				thirdParties:
					typeof thirdParties !== 'undefined' && thirdParties.length > 0
						? formatThirdParty
						: section1.thirdParties,
			});
			setToggle(mv_incident);
			setThirdPartyToggle(thirdParty);
		}
		//removes dependency for "section 1"
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleOnBlur = async () => {
		if (!sectionId || disableIncidentWriteAccess) {
			return;
		}
		const formatIncidentTime =
			section1.incident_time && section1.incident_time.length < 6
				? `${section1.incident_time}:00.000`
				: section1.incident_time;

		let branchName = '';
		branchOptions.forEach((item) => {
			if (item.id === section1.branch) {
				branchName = item.label;
			}
		});

		if (branchName === '') {
			branchName = section1.branch;
		}

		const formattedThirdParties = section1?.thirdParties?.map((thirdParty) => {
			return {
				...thirdParty,
			};
		});

		const payload: IVehicleIncidentSection1Payload = {
			id: sectionId,
			incidentId: IncidentState.incidentReportId,
			branch: branchName,
			incident_date: section1.incident_date.toString(),
			reported_date: section1.reported_date.toString(),
			incident_time: formatIncidentTime,
			location: section1.location,
			incident_description: section1.description,
			client_name: section1.client,
			job_details: section1.jobDetails,
			mv_incident: toggle,
			altusRegistration: section1.altusRegistration,
			driverName: section1.driverName,
			licenseClass: section1.licenseClass,
			licenseExpiry: section1.licenseExpiry,
			thirdParty: thirdPartyToggle,
			thirdParties: thirdPartyToggle ? formattedThirdParties : [],
		};

		await updateIncidentSection1(payload);

		setSection1IncidentErrorStates({
			section_1_incident_description: section1.description !== '',
			section_1_location: section1.location !== '',
			section_1_client_name: section1.client !== '',
			section_1_job_details: section1.jobDetails !== '',
			section_1_incident_date: section1.incident_date ? true : false,
			section_1_reported_date: section1.reported_date ? true : false,
			section_1_incident_time: section1.incident_time !== '',
		});
	};

	const updatePayload = async (thirdParties: IThirdParty[]) => {
		const formattedThirdParties = thirdParties.map(
			(thirdParty: IThirdParty) => {
				return {
					...thirdParty,
				};
			}
		);

		if (!sectionId) {
			return;
		}

		const formatIncidentTime =
			section1.incident_time && section1.incident_time.length < 6
				? `${section1.incident_time}:00.000`
				: section1.incident_time;

		let branchName = '';
		branchOptions.forEach((item) => {
			if (item.id === section1.branch) {
				branchName = item.label;
			}
		});

		if (branchName === '') {
			branchName = section1.branch;
		}

		const payload: IVehicleIncidentSection1Payload = {
			id: sectionId,
			incidentId: IncidentState.incidentReportId,
			branch: branchName,
			incident_date: section1.incident_date.toString(),
			reported_date: section1.reported_date.toString(),
			incident_time: formatIncidentTime,
			location: section1.location,
			incident_description: section1.description,
			client_name: section1.client,
			job_details: section1.jobDetails,
			mv_incident: toggle,
			altusRegistration: section1.altusRegistration,
			driverName: section1.driverName,
			licenseClass: section1.licenseClass,
			licenseExpiry: section1.licenseExpiry,
			thirdParty: thirdPartyToggle,
			thirdParties: formattedThirdParties,
		};

		await updateIncidentSection1(payload);
	};

	const addThirdParty = async () => {
		if (disableIncidentWriteAccess) return;
		const { thirdParties } = section1;
		let assignId = 1;
		if (thirdParties && thirdParties.length > 0) {
			assignId = thirdParties[thirdParties.length - 1].id + 1;
		} else {
			assignId += 1;
		}

		const addThirdParty = [
			...(section1.thirdParties || []),
			{
				id: assignId,
				thirdParty_name: '',
				thirdParty_vehicle_registration: '',
				thirdParty_licence_number: '',
				thirdParty_phone_number: '',
			},
		];

		setSection1((section1) => {
			return {
				...section1,
				thirdParties: addThirdParty,
			};
		});

		updatePayload(addThirdParty);
	};

	const deleteThirdParty = async (id: number) => {
		if (disableIncidentWriteAccess) return;

		const filteredList = section1?.thirdParties?.filter(
			(thirdParty) => thirdParty.id !== id
		);
		setSection1((prevSection1) => {
			return {
				...prevSection1,
				thirdParties: filteredList,
			};
		});

		updatePayload(filteredList || []);
	};

	const handleChangeAndBlur = (
		property: ISection1Type,
		value: Date | string,
		index?: number
	) => {
		handleSection1Update(property, value, index);
		handleOnBlur();
	};

	return (
		<>
			{isLoading ? (
				<>
					<p>Loading..</p>
				</>
			) : (
				<>
					<Heading type="h2">Incident Description</Heading>
					<form>
						<div className="flex flex-wrap gap-8">
							<div className="basis-1/5 grow">
								<DateInput
									placeholder="Select Date"
									onChange={(value: Date) =>
										handleSection1Update('incident_date', value)
									}
									onBlur={handleOnBlur}
									className="mb-10"
									label="Incident Date"
									required={true}
									iconClassName="text-black"
									selected={section1.incident_date}
									wrapperClassName="react-datepicker-margin-0"
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div className="basis-1/5 grow">
								<Input
									type="time"
									onChange={(value: string) =>
										handleSection1Update('incident_time', value)
									}
									onBlur={handleOnBlur}
									value={section1.incident_time}
									className="mb-10"
									required={true}
									label="Incident time"
									Icon={ClockIcon}
									iconClassName="text-black"
									iconPosition="trailing"
									placeholder="Select Time"
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div className="basis-1/5 grow">
								<ComboBox
									label="Branch"
									options={branchOptions}
									isDisabled={disableIncidentWriteAccess}
									required={true}
									onChange={(value: string) =>
										handleChangeAndBlur('branch', value)
									}
									selected={section1.branch}
								/>
							</div>
							<div className="basis-1/5 grow">
								<DateInput
									placeholder="Select Date"
									onChange={(value: Date) =>
										handleSection1Update('reported_date', value)
									}
									onBlur={handleOnBlur}
									className="mb-10"
									label="Reported Date"
									required={true}
									iconClassName="text-black"
									selected={section1.reported_date}
									wrapperClassName="react-datepicker-margin-0"
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
						</div>
						<div>
							<TextareaInput
								value={section1.description}
								label="Incident Description"
								required={true}
								resize={true}
								rows={6}
								cols={37}
								placeholder="Description"
								onChange={(value: string) =>
									handleSection1Update('description', value)
								}
								onBlur={handleOnBlur}
								isDisabled={disableIncidentWriteAccess}
							/>
							<Text className="py-4 text-xs">
								Clearly state what happened in the incident, including the
								outcome and sequence of events leading up to the incident.
							</Text>
						</div>
						<div className="flex flex-wrap gap-8 mt-4">
							<div className="basis-1/4 grow">
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('client', value)
									}
									onBlur={handleOnBlur}
									value={section1.client}
									className=""
									required={true}
									label="Client Name"
									placeholder="Name"
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div className="basis-1/4 grow">
								<TextareaInput
									value={section1.location}
									label="Exact Location"
									required={true}
									rows={6}
									cols={37}
									placeholder="Description"
									resize={true}
									onChange={(value: string) =>
										handleSection1Update('location', value)
									}
									onBlur={handleOnBlur}
									isDisabled={disableIncidentWriteAccess}
								/>
								<Text className="py-4 text-xs">
									Location where the incident happened. (Location, Address or
									area)
								</Text>
							</div>
							<div className="basis-1/4 grow">
								<TextareaInput
									onChange={(value: string) =>
										handleSection1Update('jobDetails', value)
									}
									onBlur={handleOnBlur}
									value={section1.jobDetails}
									label="Job Details"
									required={true}
									rows={6}
									cols={37}
									placeholder="Description"
									resize={true}
									isDisabled={disableIncidentWriteAccess}
								/>
								<Text className="py-4 text-xs">
									Clearly state Client/Job details
								</Text>
							</div>
						</div>
						<div className="w-full h-0 my-5 border-t border-primary-200" />
						<ToggleButton
							toggle={toggle}
							setToggle={setToggle}
							onBlur={handleOnBlur}
							label="Would you like to enter details of an MVI or MVI3 incident? *"
							isDisabled={disableIncidentWriteAccess}
						/>
						{toggle && (
							<div className="flex flex-wrap gap-8 my-6">
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('altusRegistration', value)
									}
									onBlur={handleOnBlur}
									value={section1.altusRegistration}
									className="basis-1/5 grow"
									label="Altus Registration"
									isDisabled={disableIncidentWriteAccess}
								/>
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('driverName', value)
									}
									onBlur={handleOnBlur}
									value={section1.driverName}
									className="basis-1/5 grow"
									label="Name of driver"
									isDisabled={disableIncidentWriteAccess}
								/>
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('licenseClass', value)
									}
									onBlur={handleOnBlur}
									value={section1.licenseClass}
									className="basis-1/5 grow"
									label="Licence # & Class"
									isDisabled={disableIncidentWriteAccess}
								/>
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('licenseExpiry', value)
									}
									onBlur={handleOnBlur}
									value={section1.licenseExpiry}
									className="basis-1/5 grow"
									label="Licence Expiry"
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
						)}
						<div className="w-full h-0 my-5 border-t border-primary-200" />

						{/* Third Party */}
						<ToggleButton
							toggle={thirdPartyToggle}
							setToggle={setThirdPartyToggle}
							onBlur={handleOnBlur}
							label="Is there a third party involved?"
							isDisabled={disableIncidentWriteAccess}
						/>
						{thirdPartyToggle && (
							<>
								{section1?.thirdParties &&
									section1?.thirdParties?.length > 0 &&
									section1?.thirdParties?.map((thirdParty, index) => {
										const { id } = thirdParty;
										return (
											<ThirdParty
												key={id}
												handleSection1Update={handleSection1Update}
												onBlur={handleOnBlur}
												deleteThirdParty={deleteThirdParty}
												id={id}
												thirdParty={thirdParty}
												index={index}
												isDisabled={disableIncidentWriteAccess}
											/>
										);
									})}
								<Button
									onClick={addThirdParty}
									type="secondary"
									className="font-bold mr-4"
									isDisabled={disableIncidentWriteAccess}
								>
									<PlusIcon height={18} width={18} className="mr-2" />
									Add another witness
								</Button>
							</>
						)}
						<div className="w-full h-0 my-5 border-t border-primary-200" />
						<div className="flex justify-end">
							<Button
								onClick={() => setSection('section2')}
								type="primary"
								className="font-bold mr-4"
							>
								Continue to Person Involved
							</Button>
						</div>
					</form>
				</>
			)}
		</>
	);
};

export default IncidentSection1;
