//finds the time difference between 2 dates
export const findDateDifference = (
	dateOne: string | Date | null,
	dateTwo: string | Date | null
) => {
	if (!dateOne || !dateTwo) {
		return;
	}

	const firstDate = new Date(dateOne);
	const secondDate = new Date(dateTwo);
	const daysDifference = secondDate.getTime() - firstDate.getTime();
	const seconds = Math.floor(daysDifference / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);

	if (days > 0) {
		return `${days} days ${hours} hours`;
	} else if (hours > 0) {
		return `${hours} hours ${minutes} mins`;
	} else {
		return `${minutes} mins`;
	}
};
