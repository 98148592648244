import Input from 'components/atoms/Input';
import React, { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { ISection1Type } from 'types/VehicleIncidents';
import Button from 'components/atoms/Button';

export interface ThirdPartyType {
	handleSection1Update: (
		property: ISection1Type,
		sectionLabel: string,
		index?: number
	) => void;
	deleteThirdParty(id: number): void;
	id: number;
	thirdParty: IThirdParty;
	index: number;
	isDisabled: boolean;
	onBlur?: () => void;
}

export interface IThirdParty {
	id: number;
	thirdParty_name: string;
	thirdParty_vehicle_registration: string;
	thirdParty_licence_number: string;
	thirdParty_phone_number: string;
}

const ThirdParty: React.FC<ThirdPartyType> = ({
	handleSection1Update,
	deleteThirdParty,
	thirdParty,
	id,
	index,
	isDisabled = false,
	onBlur,
}) => {
	const [payload, setPayload] = useState(thirdParty);

	useEffect(() => {
		setPayload(thirdParty);
	}, [thirdParty]);

	const handleOnChange = (property: any, value: string) => {
		handleSection1Update(property, value, index);
	};

	return (
		<div className="flex gap-x-8 w-full flex-wrap my-3">
			<Input
				type="text"
				onChange={(value: string) => handleOnChange('thirdParty_name', value)}
				value={payload.thirdParty_name}
				onBlur={onBlur}
				className="basis-1/5 grow"
				label="Name"
				required={true}
				isDisabled={isDisabled}
			/>
			<Input
				type="text"
				onChange={(value: string) =>
					handleOnChange('thirdParty_vehicle_registration', value)
				}
				value={payload.thirdParty_vehicle_registration}
				onBlur={onBlur}
				className="basis-1/5 grow"
				label="Vehicle Registration"
				required={true}
				isDisabled={isDisabled}
			/>
			<Input
				type="tel"
				onChange={(value: string) =>
					handleOnChange('thirdParty_licence_number', value)
				}
				onBlur={onBlur}
				value={payload.thirdParty_licence_number}
				className="basis-1/5 grow"
				label="Licence Number"
				required={true}
				isDisabled={isDisabled}
			/>
			<Input
				type="tel"
				onChange={(value: string) =>
					handleOnChange('thirdParty_phone_number', value)
				}
				onBlur={onBlur}
				value={payload.thirdParty_phone_number}
				className="basis-1/5 grow"
				label="Phone Number"
				required={true}
				isDisabled={isDisabled}
			/>
			<div className="flex items-center mt-6">
				<Button
					type="quinary"
					className="font-bold"
					onClick={() => deleteThirdParty(id)}
					size="md"
				>
					<TrashIcon height={20} width={18} className="cursor-pointer" />
					&nbsp;Delete
				</Button>
			</div>
		</div>
	);
};

export default ThirdParty;
