import Heading from 'components/atoms/Heading';
import SurveyResponsesTable, {
	SurveyResponsesTableMapper,
} from 'components/molecules/SurveyResponsesTable/SurveyResponsesTable';
import React from 'react';
import { ISurveyAnswer } from 'types/Surveys';

export interface ISurveyResponsesTableCard {
	id: number;
	isComment?: boolean;
	isSuggestion?: boolean;
	question: string;
	total: number;
	surveyAnswers: ISurveyAnswer[];
}

const SurveyResponsesTableCard: React.FC<ISurveyResponsesTableCard> = ({
	id,
	isComment,
	isSuggestion,
	question,
	total,
	surveyAnswers,
}) => {
	const getTableMap: (
		isComment: boolean,
		isSuggestion: boolean
	) => SurveyResponsesTableMapper<ISurveyAnswer> = (
		isComment,
		isSuggestion
	) => {
		const maps: SurveyResponsesTableMapper<ISurveyAnswer> = [
			{
				heading: 'Name',
				render: (prop) =>
					`${prop.survey_submission.first_name} ${prop.survey_submission.last_name}`,
			},
		];

		if (isComment) {
			maps.push(
				{
					heading: 'email',
					render: (prop) => prop.survey_submission.email,
				},
				{
					heading: 'job ID',
					render: (prop) => prop.survey_submission.job_number,
				}
			);
		}

		if (isSuggestion) {
			maps.push({
				heading: 'Contact No',
				render: (prop) => prop.survey_submission.contact,
			});
		}

		maps.push({
			heading: isComment || isSuggestion ? 'Comment' : 'Answer',
			render: (prop) => prop.answer,
		});

		return maps;
	};

	return (
		<div
			className="flex flex-col w-full p-8 space-y-2 bg-white rounded-lg text-secondary-500"
			key={id}
		>
			<p className="font-extralight ">
				{isComment ? 'Comment' : isSuggestion ? 'Suggestion' : question}
			</p>
			<Heading type="h2">{total}</Heading>

			<div className="flex">
				<SurveyResponsesTable
					data={surveyAnswers}
					mapper={getTableMap(isComment || false, isSuggestion || false)}
				/>
			</div>
		</div>
	);
};

export default SurveyResponsesTableCard;
