import { IAnswerReport } from 'types/AnswerReport';

export interface ISurveyTable {
	data: IAnswerReport[];
	total?: number;
	totalVote?: number;
	charColors: string[];
	isNPS: boolean;
}

export const SurveyTable: React.FC<ISurveyTable> = ({
	data,
	total,
	totalVote,
	charColors,
	isNPS,
}) => {
	const getScore = (score: IAnswerReport) => {
		if (score.total === 0) {
			return Number(0).toFixed(2);
		}
		return (
			(((score.promoter || 0) - (score.detractor || 0)) * 100) /
			score.total
		).toFixed(2);
	};

	return (
		<table className="h-fit text-secondary-50">
			<thead className="text-xs border-b-2">
				<tr className="">
					<th className="py-4 text-left font-bold w-28">
						{isNPS ? 'State' : 'Score'}
					</th>
					<th className="py-4 text-right font-bold w-12">
						{isNPS ? 'Responses' : 'Total'}
					</th>
					<th className="py-4 text-right font-bold w-28">
						{isNPS ? 'Score' : '%'}
					</th>
				</tr>
			</thead>
			<tbody className="text-sm">
				{data.map((row, index) => (
					<tr className="" key={row.answer}>
						<td className="py-2 text-secondary-200">{row.answer}</td>
						<td className="text-right text-secondary-500">{row.total}</td>
						<td className="text-right ">
							<p
								className="p-1 text-secondary-500 ml-4"
								style={{ backgroundColor: charColors[index] }}
							>
								{isNPS
									? getScore(row) || 0
									: `${((row.total * 100) / (totalVote || 1)).toFixed(2)} %`}
							</p>
						</td>
					</tr>
				))}

				{!isNPS && (
					<tr className="">
						<td className="py-2 font-bold text-secondary">Total: {total}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};
