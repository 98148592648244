import { baseAPI } from './baseAPI';

interface INavigationTotalResponse {
	incidents: number;
	vehicleFaults: number;
	surveys: number;
}

export const navigationAPI = baseAPI.injectEndpoints({
	endpoints: (builder) => ({
		navigationTotal: builder.query<INavigationTotalResponse, void>({
			query() {
				return {
					url: '/navigation',
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useNavigationTotalQuery } = navigationAPI;
