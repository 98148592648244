import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { ChartOptions } from 'chart.js/dist/types/index';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { IBranchesTotalByType } from 'types/Branches';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface IBranchTotalGraph {
	className?: string;
	branches: IBranchesTotalByType[];
}

const BranchTotalGraph: React.FC<IBranchTotalGraph> = ({
	className,
	branches,
}) => {
	const filteredBranches = branches.filter((branch) => branch.total);

	const data = {
		labels: filteredBranches.map((branch) => branch.name),
		datasets: [
			{
				label: '# of Votes',
				data: filteredBranches.map((branch) => branch.total),
				backgroundColor: [
					'#5B8FF9',
					'#5AD8A6',
					'#5D7092',
					'#F6BD16',
					'#E8684A',
					'#6DC8EC',
				],
				borderColor: ['white'],
				borderWidth: 2,
				cutout: '65%',
			},
		],
	};

	const options: ChartOptions<'doughnut'> = {
		plugins: {
			legend: {
				position: 'bottom',
				align: 'start',
				fullSize: true,
				labels: {
					usePointStyle: true,
					pointStyle: 'circle',
					boxHeight: 8,
					font: {
						size: 16,
					},
				},
			},
			tooltip: {
				enabled: false,
			},
			datalabels: {
				color: 'blue',
				labels: {
					value: {
						color: ['black', 'black', 'white', 'black', 'black', 'black'],
						display: filteredBranches.map((branch) => branch.total > 0),
						font: {
							size: 16,
						},
					},
				},
			},
		},
	};

	return (
		<div className={`flex justify-center ${className}`}>
			{/*@ts-ignore*/}
			<Doughnut plugins={[ChartDataLabels]} data={data} options={options} />
		</div>
	);
};

export default BranchTotalGraph;
