import { useEffect } from 'react';
import {
	IIncident,
	IOption,
	ISection3ErrorStates,
} from 'types/VehicleIncidents';
import { IVehicleIncidentSection3 } from 'types/VehicleIncidents';
import { isCheckBoxChecked } from 'utils/isCheckBoxChecked';

export const useGetIncidentSection3 = (
	isSuccess: boolean,
	data: IIncident | undefined,
	setIncidentSection3: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection3>
	>,
	setSection3IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection3ErrorStates>
	>
) => {
	useEffect(() => {
		if (isSuccess && data) {
			const {
				injuries,
				body_injured,
				injury_type,
				incident_mechanism,
				incident_agency,
				injury_type_other_description,
				incident_mechanism_other_description,
				incident_agency_other_description,
				body_injured_other_description,
			} = data.incidentData.incident_section_3 ?? {
				injuries: [],
				body_injured: [],
				injury_type: [],
				incident_mechanism: [],
				incident_agency: [],
				injury_type_other_description: null,
				incident_mechanism_other_description: null,
				incident_agency_other_description: null,
				body_injured_other_description: null,
			};

			const bodyPartInjuredArray: IOption[] = [];
			const bodyPartInjuredArray2 = [...body_injured];
			const arrayToFilter = ['Left', 'First Right', 'Upper', 'Lower'];

			for (let i = body_injured.length - 1; i >= 0; i--) {
				if (arrayToFilter.includes(body_injured[i].title)) {
					bodyPartInjuredArray.push(body_injured[i]);
					bodyPartInjuredArray2.splice(i, 1);
				}
			}
			bodyPartInjuredArray.reverse();

			setIncidentSection3((prevState) => ({
				...prevState,
				bodyParts: bodyPartInjuredArray,
				bodyPartsInjured: bodyPartInjuredArray2,
				bodyPartsInjuredOtherInput: body_injured_other_description,
				injuryType: injury_type,
				injuryTypeOtherInput: injury_type_other_description,
				incidentMechanism: incident_mechanism,
				incidentMechanismOtherInput: incident_mechanism_other_description,
				incidentAgency: incident_agency,
				incidentAgencyOtherInput: incident_agency_other_description,
				injuries,
			}));

			const bodyInjuredCheckboxGroup = [
				...bodyPartInjuredArray,
				...bodyPartInjuredArray2,
			];

			setSection3IncidentErrorStates({
				section_3_body_parts: isCheckBoxChecked(bodyInjuredCheckboxGroup),
				section_3_injury_type: isCheckBoxChecked(injury_type),
				section_3_incident_mechanism: isCheckBoxChecked(incident_mechanism),
				section_3_incident_agency: isCheckBoxChecked(incident_agency),
			});
		}
	}, [isSuccess, data, setIncidentSection3, setSection3IncidentErrorStates]);
};
