import Heading from 'components/atoms/Heading';
import SurveyResponsesNpsScoreCard from 'components/molecules/SurveyResponsesNpsScoreCard';
import React from 'react';
import { useGetSurveyNpsScoreReportQuery } from 'redux/api/reportsAPI';
import { IFilters } from 'types/Surveys';
import { isEmptyObject } from 'utils/isEmptyObject';

export interface INpsScoreSection {
	chartColors: string[];
	type: string;
	filter: IFilters;
}

const NpsScoreSection: React.FC<INpsScoreSection> = ({
	chartColors,
	type,
	filter,
}) => {
	const getFilterQuery = () => {
		return `${Object.keys(filter)
			.filter((key) => filter[key] && filter[key]?.length)
			.map((key) => `${key}=${filter[key]}`)
			.join('&')}&type=${type}`;
	};
	const { data } = useGetSurveyNpsScoreReportQuery(getFilterQuery());

	return (
		<div className="flex flex-col mt-8 space-y-8">
			<>
				{data && !isEmptyObject(data) && (
					<div className="flex p-8 space-x-8 bg-white rounded-lg w-fit">
						{data?.satisfaction && (
							<div className="flex flex-col">
								<Heading type="h2">{data?.satisfaction.percentage} %</Heading>
								<span className="font-thin">Satisfaction</span>
							</div>
						)}
						{data?.loyalty && (
							<div className="flex flex-col">
								<Heading type="h2">{data?.loyalty.percentage} %</Heading>
								<span className="font-thin">Loyalty</span>
							</div>
						)}
						{data?.growth && (
							<div className="flex flex-col">
								<Heading type="h2">{data?.growth.percentage} %</Heading>
								<span className="font-thin">Growth</span>
							</div>
						)}
					</div>
				)}

				{data?.satisfaction && (
					<SurveyResponsesNpsScoreCard
						chartColors={chartColors}
						question={'Satisfaction Scores by State'}
						scores={data.satisfaction.states}
						total={data.satisfaction.total}
					/>
				)}

				{data?.loyalty && (
					<SurveyResponsesNpsScoreCard
						chartColors={chartColors}
						question={'Loyalty Scores by State'}
						scores={data.loyalty.states}
						total={data.loyalty.total}
					/>
				)}

				{data?.growth && (
					<SurveyResponsesNpsScoreCard
						chartColors={chartColors}
						question={'Growth Scores by State'}
						scores={data.growth.states}
						total={data.growth.total}
					/>
				)}
			</>
		</div>
	);
};

export default NpsScoreSection;
