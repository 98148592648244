import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { useAppSelector } from 'redux/hooks';
import { updateFilterState } from 'redux/slices/vehicleFaultSlice';
import { useDispatch } from 'react-redux';
import { updateIncidentFilterState } from 'redux/slices/vehicleIncidentsSlice';
import { updateSurveySearchFilterState } from 'redux/slices/surveys';

import React from 'react';

export interface ISelect {
	label?: string;
	id?: string;
	options: { id: string | number; label: string }[];
	selected?: string;
	onChange?: (item: string) => void;
	placeholder?: string;
	className?: string;
	size?: 'normal' | 'small';
	required?: boolean;
	errorMessage?: string;
	disabled?: boolean;
	onBlur?: () => void;
	filterType?: 'incident' | 'fault' | 'survey';
	filterName?: 'status' | 'surveySearchType';
	maxNumberToScroll?: 2 | 3 | 4 | 5 | 6;
}

const Select: React.FC<ISelect> = ({
	label,
	options,
	selected: defaultSelected,
	onChange,
	placeholder = 'Select',
	className = '',
	size = 'normal',
	required = false,
	errorMessage,
	disabled,
	onBlur,
	filterType = '',
	filterName = '',
	maxNumberToScroll = 6,
}) => {
	const dispatch = useDispatch();
	const filters = useAppSelector((state) => state.vehicleFault.filters);
	const incidentFilters = useAppSelector(
		(state) => state.vehicleIncident.filters
	);
	const surveyFilters = useAppSelector((state) => state.surveys.searchFilter);
	const [selected, setSelected] = useState(defaultSelected);
	const NUMBER_TO_SCROLL = maxNumberToScroll;
	let scrollHeightStyle = 'max-h-72';

	if (maxNumberToScroll) {
		switch (maxNumberToScroll) {
			case 2:
				scrollHeightStyle = 'max-h-24';
				break;
			case 3:
				scrollHeightStyle = 'max-h-36';
				break;
			case 4:
				scrollHeightStyle = 'max-h-48';
				break;
			case 5:
				scrollHeightStyle = 'max-h-60';
				break;
			default:
				scrollHeightStyle = 'max-h-72';
		}
	}

	useEffect(() => {
		setSelected(defaultSelected);
	}, [defaultSelected]);

	const handleOnSelect = (item: string) => {
		if (filterType === '' || filterName === '') {
			setSelected(item);
			onChange?.(item);
			return;
		}

		if (filterName !== '') {
			if (filterType === 'fault') {
				const filterState = JSON.parse(JSON.stringify(filters));
				filterState[filterName] = item;
				setSelected(item);
				dispatch(updateFilterState(filterState));
			} else if (filterType === 'incident') {
				const filterState = JSON.parse(JSON.stringify(incidentFilters));
				filterState[filterName] = item;
				setSelected(item);
				dispatch(updateIncidentFilterState(filterState));
			} else if (filterType === 'survey') {
				const filterState = JSON.parse(JSON.stringify(surveyFilters));
				filterState[filterName] = item;
				setSelected(item);
				dispatch(updateSurveySearchFilterState(filterState));
			}
		}
	};

	const handleOnBlur = () => {
		if (!onBlur) return;
		onBlur();
	};

	const inputBaseStyle = errorMessage
		? 'block w-full border-red-300 focus:ring-red-500 focus:border-red-500 placeholder-red-300 focus:outline-none'
		: 'border-gray-300';

	return (
		<Listbox value={selected} onChange={handleOnSelect} disabled={disabled}>
			{({ open }) => (
				<>
					<Listbox.Label className="block font-inter text-base font-bold tracking-normal text-secondary">
						{label} {required && <span className="text-red">*</span>}
					</Listbox.Label>
					<div className={`relative ${className}`}>
						<Listbox.Button
							className={`
							${size === 'normal' ? 'h-12 py-3.5' : 'h-8 py-0'}
							border
							${open ? ' border-primary border-b-0 drop-shadow-lg' : `${inputBaseStyle}`}
							bg-white relative w-full px-4 text-left focus:outline-none sm:text-sm min-w-[150px] mt-1 ${
								!disabled && 'cursor-pointer'
							}`}
						>
							<div className="flex">
								{!selected ? (
									<span className="block truncate flex-1 text-gray-500">
										{placeholder}
									</span>
								) : (
									<span className="block truncate flex-1">
										{options &&
											options.filter((option) => option.label === selected)[0]
												?.label}
									</span>
								)}
								<span className="flex items-center pointer-events-none">
									{open ? (
										<ChevronUpIcon
											className="h-5 w-5 text-primary"
											aria-hidden="true"
										/>
									) : (
										<ChevronDownIcon
											className="h-5 w-5 text-secondary"
											aria-hidden="true"
										/>
									)}
								</span>
							</div>

							{open && (
								<div className="absolute bottom-0 left-0 px-4 w-full">
									<div className="h-0 border-t-[0.25px] border-primary w-full" />
								</div>
							)}
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options
								static
								className={`absolute z-10 py-2 w-full bg-white cursor-pointer text-base sm:text-sm border border-primary border-t-0 drop-shadow-lg ${
									options.length > NUMBER_TO_SCROLL
										? `overflow-y-auto ${scrollHeightStyle}`
										: ''
								}`}
								onBlur={handleOnBlur}
							>
								{options.map((option, idx) => (
									<Listbox.Option
										key={idx}
										className={
											'text-secondary select-none py-3 px-4 cursor-pointer'
										}
										value={option.label}
									>
										<span className={'font-normal block truncate'}>
											{option.label}
										</span>
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
					{errorMessage && (
						<p className="mt-2 text-sm text-red-600" id="email-error">
							{errorMessage}
						</p>
					)}
				</>
			)}
		</Listbox>
	);
};

export default Select;
