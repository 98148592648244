import Button from 'components/atoms/Button';
import DateInput from 'components/atoms/DateInput';
import Divider from 'components/atoms/Divider';
import Input from 'components/atoms/Input';
import Accordion from 'components/molecules/Accordion';
import CheckBoxGroup from 'components/molecules/CheckBoxGroup';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetVehicleIncidentsMutation } from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';
import {
	//resetIncidentFilterState, // May need this again soon - Ronnie
	updateIncidentFilterState,
} from 'redux/slices/vehicleIncidentsSlice';
import { IQueryPeriod } from 'types/VehicleIncidents';
import { filtersToQueryStringConverter } from 'utils/filters';

export interface IIncidentSearchBar {
	setFilters: React.Dispatch<React.SetStateAction<string>>;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setPeriod: React.Dispatch<React.SetStateAction<IQueryPeriod>>;
}

const IncidentSearchBar: React.FC<IIncidentSearchBar> = ({
	setFilters,
	setOpen,
	setPeriod,
}) => {
	const dispatch = useDispatch();

	const filters = useAppSelector((state) => state.vehicleIncident.filters);
	const [getIncidents, { data }] = useGetVehicleIncidentsMutation();
	const [filterData, setFilterData] = useState(data?.sortData);
	const [defaultDate] = useState(new Date());

	const statusData = [
		{
			id: 1,
			title: 'new',
			checked: true,
		},
		{
			id: 2,
			title: 'open',
			checked: true,
		},
		{
			id: 3,
			title: 'closed',
			checked: true,
		},
		{
			id: 4,
			title: 'draft',
			checked: true,
		},
		{
			id: 5,
			title: 'nuisance',
			checked: true,
		},
		{
			id: 6,
			title: 'duplicate',
			checked: true,
		},
	];

	const correctiveActionsData = [
		{
			id: 1,
			title: 'open',
			checked: true,
		},
		{
			id: 2,
			title: 'completed',
			checked: true,
		},
	];

	useEffect(() => {
		getIncidents('all');
	}, [getIncidents]);

	useEffect(() => {
		setFilterData(data?.sortData);
	}, [data?.sortData]);

	useEffect(() => {
		if (data?.sortData && filterData !== undefined) {
			const stateData = data?.sortData[0]?.state;
			const branchData = data?.sortData[1]?.branch;

			const filterState = JSON.parse(JSON.stringify(filters));
			filterState['state'] = stateData;
			filterState['branch'] = branchData;
			filterState['status'] = statusData;
			filterState['correctiveActions'] = correctiveActionsData;
			dispatch(updateIncidentFilterState(filterState));
		}
		//removes dependency for "filters"
		// eslint-disable-next-line
	}, [setFilterData, filterData, dispatch, data?.sortData]);

	const onClick = () => {
		const query = filtersToQueryStringConverter(filters);
		setFilters(query);
		setOpen(false);
		setPeriod('all');
		//dispatch(resetIncidentFilterState()); // May need this again soon - Ronnie
	};

	const onHandleChange = (
		filterType: string,
		value: React.ChangeEvent<HTMLInputElement> | string | Date
	) => {
		const filterState = JSON.parse(JSON.stringify(filters));
		if (value instanceof Date) {
			filterState[filterType] = value.toISOString();
		} else {
			filterState[filterType] = value;
		}

		dispatch(updateIncidentFilterState(filterState));
	};

	return (
		<>
			<Divider />
			<Accordion title="Keyword">
				<Input
					type="text"
					onChange={(value: string) => {
						onHandleChange('keyword', value);
					}}
					value={filters['keyword'] as string}
					placeholder="Search"
					size="normal"
					className="mt-1"
				/>
			</Accordion>
			<Divider />
			<Accordion title="Date Range">
				<DateInput
					inputLabel="From"
					placeholder="From"
					filterDateType="incident"
					iconClassName="text-secondary"
					selected={defaultDate}
				/>
				<DateInput
					inputLabel="To"
					placeholder="To"
					filterDateType="incident"
					iconClassName="text-secondary"
					selected={defaultDate}
				/>
			</Accordion>
			<Divider />
			<Accordion title="State">
				<CheckBoxGroup filterName="state" filterType="incident" allCheckBox />
			</Accordion>
			<Divider />
			<Accordion title="Branch">
				<CheckBoxGroup filterName="branch" filterType="incident" allCheckBox />
			</Accordion>
			<Divider />
			<Accordion title="Severity">
				<CheckBoxGroup
					filterName="severity"
					filterType="incident"
					allCheckBox
				/>
			</Accordion>
			<Divider />
			<Accordion title="Status">
				<CheckBoxGroup filterName="status" filterType="incident" allCheckBox />
			</Accordion>
			<Divider />
			<Accordion title="Code of Conduct">
				<CheckBoxGroup
					filterName="codeOfConduct"
					filterType="incident"
					allCheckBox
				/>
			</Accordion>
			<Divider />
			<Accordion title="Life Saving Rules">
				<CheckBoxGroup
					filterName="lifeSavingRules"
					filterType="incident"
					allCheckBox
				/>
			</Accordion>
			<Divider />
			<Accordion title="Incident Category">
				<CheckBoxGroup
					filterName="incidentCategory"
					filterType="incident"
					allCheckBox
				/>
			</Accordion>
			<Divider />
			<Accordion title="Corrective Actions">
				<CheckBoxGroup
					filterName="correctiveActions"
					filterType="incident"
					allCheckBox
				/>
			</Accordion>
			<Divider />
			<Button
				className="h-12 w-[222px] mx-auto my-6 font-bold"
				onClick={onClick}
			>
				Apply
			</Button>
		</>
	);
};

export default IncidentSearchBar;
