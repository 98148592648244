//formats to DD/MM/YYYY HH:MM AM/PM
export const formatDateTime = (value: string | Date | null) => {
	if (!value) {
		return;
	}

	const date = new Date(value).toLocaleDateString('en-AU');
	const time = new Date(value).toLocaleTimeString('en-AU');
	const timeArray = time.split(':');
	const getAmPm = time.slice(-2);
	return `${date} ${timeArray[0]}:${timeArray[1]} ${getAmPm}`;
};
