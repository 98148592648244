import DateInput from 'components/atoms/DateInput';
import Select from 'components/atoms/Select';
import React from 'react';
import { useEffect, useState } from 'react';
import { IIncidentCategoryBreakdownFilters } from 'types/VehicleIncidents';
import { useBranchesFromPermsQuery } from 'redux/api/branchAPI';

export interface IDashboardIncidentFilters {
	filters: IIncidentCategoryBreakdownFilters;
	setFilters: (filters: IIncidentCategoryBreakdownFilters) => void;
}

const DashboardIncidentFilters: React.FC<IDashboardIncidentFilters> = ({
	filters,
	setFilters,
}) => {
	const { data: branchData, isSuccess: branchIsSuccess } =
		useBranchesFromPermsQuery();

	const [branchOptions, setBranchOptions] = useState([
		{
			id: 0,
			label: 'blah',
		},
	]);

	useEffect(() => {
		if (branchIsSuccess && branchData) {
			setBranchOptions(branchData);
		}
	}, [branchIsSuccess, branchData]);

	const setBranch = (branchName: string) => {
		const branchId = branchOptions.find(
			(branch) => branch.label === branchName
		)?.id;

		setFilters({ ...filters, branchId: branchId || 0 });
	};

	return (
		<>
			<div className="w-60">
				<Select
					options={branchOptions}
					onChange={(option) => setBranch(option)}
					className="mt-0.5 rounded-lg"
					selected={''}
					placeholder="Branches"
				/>
			</div>
			<div className="w-30">
				<DateInput
					inputLabel="Date From"
					placeholder="Date From"
					iconClassName="text-secondary"
					onChange={(option) =>
						setFilters({ ...filters, dateFrom: option.toISOString() })
					}
				/>
			</div>
			<div className="w-30">
				<DateInput
					inputLabel="Date To"
					placeholder="Date To"
					iconClassName="text-secondary"
					onChange={(option) =>
						setFilters({ ...filters, dateTo: option.toISOString() })
					}
				/>
			</div>
		</>
	);
};

export default DashboardIncidentFilters;
