import CheckBox from 'components/atoms/CheckBox';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { IOption } from 'types/VehicleIncidents';

export interface IFormCheckBoxGroup {
	checkBoxName:
		| 'injuryType'
		| 'bodyParts'
		| 'bodyPartsInjured'
		| 'incidentMechanism'
		| 'incidentAgency'
		| 'incidentCategory'
		| 'lifeSavingRules'
		| 'codeOfConduct'
		| 'hierachyControl';
	checkBoxGroupData: IOption[];
	onHandleChange: (value: string, checkBoxGroup: IOption[]) => void;
	descriptionClassName?: string;
	descriptionDirection?: 'flex-row' | 'flex-col';
	errorState?: boolean;
	gridColumns?: '0' | '5' | '6' | '7' | '8';
	className?: string;
	otherTextBox?: boolean;
	otherTextBoxValue?: string;
	otherTextBoxOnChange?: (value: string) => void;
	otherTextBoxClassName?: string;
	isDisabled?: boolean;
	onBlur?: () => void;
}

const FormCheckBoxGroup: React.FC<IFormCheckBoxGroup> = ({
	checkBoxName,
	checkBoxGroupData,
	onHandleChange,
	descriptionClassName,
	descriptionDirection,
	errorState = false,
	gridColumns = '0',
	className = '',
	otherTextBox = false,
	otherTextBoxValue = '',
	otherTextBoxOnChange,
	otherTextBoxClassName = 'w-[400px] lg:-ml-24 rounded-lg',
	isDisabled = false,
	onBlur,
}) => {
	const errorStyle = errorState ? 'p-3 border border-red-500 rounded-lg' : '';
	const gridStyle = {
		0: '',
		5: 'grid gap-4 grid-col grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 space-y-2 ml-1',
		6: 'grid gap-2 grid-col grid-cols-3 2xl:grid-cols-6 space-y-2',
		7: 'grid gap-4 grid-col grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 space-y-2 ml-1',
		8: 'grid gap-4 grid-col grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 space-y-2 ml-1',
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (checkBoxGroupData) {
			const newCheckBoxState = [...checkBoxGroupData] as IOption[];
			const index = newCheckBoxState.findIndex(
				(item) => item.id === Number(e.target.id)
			);
			newCheckBoxState[index] = {
				...newCheckBoxState[index],
				checked: e.target.checked,
			};
			onHandleChange(checkBoxName, newCheckBoxState);
		}
	};

	return (
		<>
			<div
				className={`${className} ${gridStyle[gridColumns]} ${errorStyle}`}
				style={errorState ? { padding: '2rem' } : { padding: '0' }}
			>
				{checkBoxGroupData !== undefined &&
					(checkBoxGroupData as IOption[]).map((item: IOption) => {
						return (
							<CheckBox
								key={item.id}
								item={item}
								onChange={onChange}
								descriptionClassName={descriptionClassName}
								descriptionDirection={descriptionDirection}
								isDisabled={isDisabled}
								onBlur={onBlur}
							/>
						);
					})}
				{otherTextBox && (
					<Input
						type="text"
						value={otherTextBoxValue}
						className={`${otherTextBoxClassName}`}
						onChange={otherTextBoxOnChange}
						size="small"
						rounded="medium"
						isDisabled={isDisabled}
						onBlur={onBlur}
					/>
				)}
			</div>
			{errorState && (
				<Text className="text-red-500">
					Please tick at least one checkbox *
				</Text>
			)}
		</>
	);
};

export default FormCheckBoxGroup;
