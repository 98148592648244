import { IMultiSelectComboBoxOption } from 'components/atoms/MultiSelectComboBox/MultiSelectComboBox';
import { IGetUser } from './User';

export type IQueryPeriod = 'today' | 'week' | 'month' | 'all';
export interface IVehicleFault {
	id: number;
	alloc8_id: string;
	submitted_at: string;
	createdAt: string;
	type: 'vehicle-fault';
	updatedAt: string;
	status: 'open' | 'closed';
	priority: 'normal' | 'high';
	ticket_number: string;
	registration: string;
	branch: {
		id: string;
		name: string;
	};
	fault_created_by?: string;
	vehicle_type: string;
	operator_name: string;
	vehicle_description: string;
	km_start: string;
	numberOfFaults: number;
	lodge_fault: '' | 'Yes' | 'No';
	fault_description: string;
	vehicle_faults: {
		id: number;
		fault_type: string;
		priority: string;
	}[];
	faults: {
		question: string;
		answer: string;
	}[];
	managers: {
		id: string;
		first_name: string;
		last_name: string;
	}[];
	assignees?: IMultiSelectComboBoxOption[];
	vehicle_fault_activities?: {
		createdAt: string;
		type: string;
		name: string;
	};
	actions?: IVehicleFaultAction[];
}

export const initialFaultState: IVehicleFault = {
	id: 0,
	alloc8_id: '',
	submitted_at: '',
	createdAt: '',
	type: 'vehicle-fault',
	updatedAt: '',
	status: 'open',
	priority: 'normal',
	ticket_number: '',
	registration: '',
	branch: {
		id: '',
		name: '',
	},
	fault_created_by: '',
	vehicle_type: '',
	operator_name: '',
	vehicle_description: '',
	km_start: '',
	numberOfFaults: 0,
	lodge_fault: '',
	fault_description: '',
	vehicle_faults: [],
	faults: [],
	managers: [],
	assignees: [],
	actions: [],
};

export interface IVehicleFaultAction {
	id: number;
	action_description: string;
	assignee?: IGetUser | string | Number;
	action_taken: string;
	completion_date: string | Date;
	completed: boolean;
}

export const initialVehicleFaultActionState = {
	id: 0,
	action_description: '',
	action_taken: '',
	completion_date: '',
	assignee: '',
	completed: false,
};

export interface IVehicleFaultActionsResponse {
	fault: IVehicleFault;
	users: IMultiSelectComboBoxOption[];
}

export interface IVehicleCreateFaultPayload {
	ticket_number: string;
	incidentDate: string | undefined;
	registration: string;
	vehicleType: string;
	branch: number | null;
	description: string;
	faults: {
		id: number;
		fault_type: string;
		priority: string;
	}[];
	assignees: IMultiSelectComboBoxOption[];
	status: string;
}

export interface IVehicleFaultQuestion {
	id: number;
	question: string;
	alloc8_id: string;
	createdAt: string;
	updatedAt: string;
}

export interface IVehicleFaultTypes {
	fault_options: { id: string; label: string }[];
}

export interface ISortFaultData {
	branch: IOption[];
	priority: IOption[];
}

export interface IFilters {
	[name: string]: IOption[] | string | null;
}
export interface IOption {
	id: number;
	title: string;
	checked: boolean;
}

const branches = [
	{ id: 1, title: 'Adelaide', checked: true },
	{ id: 2, title: 'Adelaide Away Work', checked: true },
	{ id: 3, title: 'Altus People', checked: true },
];

const vehicles = [
	{ id: 1, title: 'Ute', checked: true },
	{ id: 2, title: 'TMA', checked: true },
	{ id: 3, title: 'Drop Deck', checked: true },
];

const priorities = [
	{ id: 1, title: 'low', checked: true },
	{ id: 2, title: 'medium', checked: true },
	{ id: 3, title: 'high', checked: true },
];

const statuses = [
	{ id: 1, title: 'open', checked: true },
	{ id: 2, title: 'closed', checked: true },
];

export const initialFilterState: IFilters = {
	branch: branches,
	vehicle: vehicles,
	priority: priorities,
	status: statuses,
	to: null,
	from: null,
	registration: '',
};

export const vehicleFaultRadioButtons = [
	{
		id: 'open',
		title: 'Open',
	},
	{
		id: 'closed',
		title: 'Closed',
	},
];

export const vehicleTypeOptions = [
	{
		id: '1',
		label: 'Ute',
	},
	{
		id: '2',
		label: 'TMA',
	},
	{
		id: '3',
		label: 'Drop Deck',
	},
];

export const vehiclePriorityOptions = [
	{
		id: '1',
		label: 'Low',
	},
	{
		id: '2',
		label: 'Med',
	},
	{
		id: '3',
		label: 'High',
	},
];
