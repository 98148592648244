import { useState, ReactNode } from 'react';
import Heading from 'components/atoms/Heading';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import SlideDown from 'components/atoms/SlideDown';

export interface IAccordion {
	title: string;
	children: ReactNode;
	leadingChevron?: boolean;
	width?: string;
	titleColour?: 'primary' | 'secondary' | 'secondary-800';
}

const Accordion: React.FC<IAccordion> = ({
	title,
	children,
	leadingChevron = false,
	width = 'max-w-[222px]',
	titleColour = 'secondary',
}) => {
	const [open, setOpen] = useState(false);
	return (
		<div className={`flex flex-col ${width} justify-center my-4 mx-auto`}>
			<div className="flex w-full flex-row">
				{!leadingChevron && (
					<Heading
						type="h3"
						colour={titleColour}
						className="flex flex-1 my-auto"
					>
						{title}
					</Heading>
				)}
				<button onClick={() => setOpen(!open)}>
					{open ? (
						<ChevronUpIcon
							className={`h-5 w-5 ${
								titleColour ? 'text-secondary-800' : 'text-primary'
							}`}
							aria-hidden="true"
						/>
					) : (
						<ChevronDownIcon
							className="h-5 w-5 text-primary"
							aria-hidden="true"
						/>
					)}
				</button>
				{leadingChevron && (
					<Heading
						type="h4"
						colour={open ? titleColour : 'secondary-300'}
						className={`pl-5 flex flex-1 my-auto ${
							open ? 'font-bold' : 'font-base'
						}`}
						bold={false}
					>
						{title}
					</Heading>
				)}
			</div>
			<SlideDown open={open} className={`${open ? 'my-2' : 'hidden'}`}>
				{children}
			</SlideDown>
		</div>
	);
};

export default Accordion;
