import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import { Pill } from 'components/atoms/Pill';
import Share from 'components/atoms/Share/Share';
import TabLink from 'components/atoms/TabLink';
import NpsScoreSection from 'components/organisms/NpsScoreSection';
import SurveyResponsesSection from 'components/organisms/SurveyResponsesSection';
import MonthlyReportSection from 'components/organisms/MonthlyReportSection';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { closeSurveyReportModal } from 'redux/slices/surveys';

export type IReportPanelTab =
	| 'survey-responses'
	| 'nps-score'
	| 'detailed-monthly-report';

export interface IReportPanel {
	type: string;
}

const ReportPanel: React.FC<IReportPanel> = ({ type }) => {
	const dispatch = useDispatch();

	const [tab, setTab] = useState<IReportPanelTab>('survey-responses');

	const filter = useAppSelector((app) => {
		if (type === 'Clients') {
			return app.surveys.clientReportFilter;
		} else if (type === 'Team Leads') {
			return app.surveys.leadReportFilter;
		} else {
			return app.surveys.trafficControllerReportFilter;
		}
	}) as {
		company: string;
		from: string;
		end: string;
		client: string;
		employee: string;
		state: string;
		branch: string;
		dateType: string;
	};

	const chartColors = [
		'#BFF9CB',
		'#E4FDAE',
		'#E8D7FF',
		'#CCCBFF',
		'#CBE0FF',
		'#C9F2FB',
		'#FFDDDD',
		'#FFDBEC',
		'#DDDBD5',
		'#EDEBD5',
	];

	const handleClickTab = (tab: IReportPanelTab) => {
		setTab(tab);
	};

	const handleClickBack = () => {
		dispatch(closeSurveyReportModal());
	};

	return (
		<div id="report-panel" className="p-4 lg:w-[calc(100vw-10rem)]">
			<div className="flex flex-row space-x-4">
				<div className="flex items-center">
					<Button
						type="icon"
						navigate="previous"
						size="xs"
						className="font-bold border-0"
						onClick={handleClickBack}
					/>
					<span className="h-10 pr-4 mr-4 border-r text-primary-500 border-r-primary-500" />
					<Heading className="" type="h1">
						Report
					</Heading>
				</div>
				<div className="flex flex-row flex-1 space-x-4">
					{filter.company && (
						<Pill title="Company:" value={`${filter.company}`} />
					)}
					{filter.client && <Pill title="Client:" value={`${filter.client}`} />}
					{filter.employee && (
						<Pill title="Employee:" value={`${filter.employee}`} />
					)}
					{filter.from && filter.end && (
						<Pill
							title="Date Range:"
							value={`${new Date(
								String(filter.from)
							).toLocaleDateString()} - ${new Date(
								String(filter.end)
							).toLocaleDateString()}`}
						/>
					)}
					<Pill
						title="Branch:"
						value={`${filter.branch.length ? filter.branch : 'All Branches'}`}
					/>
					{!filter.branch && filter.state && (
						<Pill title="State:" value={filter.state} />
					)}
					{filter.from && filter.end && (
						<Pill
							title="Action Type:"
							value={`${
								filter.dateType === 'responded'
									? 'Responded During Date Range'
									: 'Sent During Date Range'
							}`}
						/>
					)}

					<div className="flex items-end flex-1">
						<Share
							options={[
								{ id: 'csv', label: 'CSV' },
								{ id: 'pdf', label: 'PDF' },
							]}
							placeholder="Share"
							filter={filter}
							type={type}
							tab={tab}
						/>
					</div>
				</div>
			</div>

			<div className="flex mt-8 space-x-8 text-base text-secondary-500">
				<TabLink
					active={tab === 'survey-responses'}
					onClick={() => handleClickTab('survey-responses')}
				>
					Survey Responses
				</TabLink>
				<TabLink
					active={tab === 'nps-score'}
					onClick={() => handleClickTab('nps-score')}
				>
					NPS Score
				</TabLink>
				{type === 'Clients' && (
					<TabLink
						active={tab === 'detailed-monthly-report'}
						onClick={() => handleClickTab('detailed-monthly-report')}
					>
						Detailed Monthly Report
					</TabLink>
				)}
			</div>

			{tab === 'survey-responses' && (
				<SurveyResponsesSection
					chartColors={chartColors}
					type={type}
					filter={filter}
				/>
			)}
			{tab === 'nps-score' && (
				<NpsScoreSection
					chartColors={chartColors}
					type={type}
					filter={filter}
				/>
			)}
			{tab === 'detailed-monthly-report' && (
				<MonthlyReportSection type={type} filter={filter} />
			)}
		</div>
	);
};

export default ReportPanel;
