import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js';
import { ChartOptions } from 'chart.js/dist/types/index';
import Heading from 'components/atoms/Heading';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { IAnswerReport } from 'types/AnswerReport';

import { SurveyTable } from '../SurveyTable';

ChartJS.register(ArcElement, Tooltip);

export interface ISurveyResponsesPieChartCard {
	chartColors: string[];
	question: string;
	answers: IAnswerReport[];
	total: number;
}

const SurveyResponsesPieChartCard: React.FC<ISurveyResponsesPieChartCard> = ({
	chartColors,
	question,
	answers,
	total,
}) => {
	const getTotalVote = () => {
		return answers.reduce((prev, a) => prev + a.total, 0);
	};

	const data = {
		labels: answers.map((x) => x.answer),
		datasets: [
			{
				data: answers.map((x) => x.total),
				backgroundColor: chartColors,
				borderWidth: 0,
			},
		],
	};

	const options: ChartOptions<'doughnut'> = {
		layout: {
			padding: 40,
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: (ctx) => {
						const numOfVote = ctx.parsed;
						return ` ${((numOfVote * 100) / getTotalVote()).toFixed(2)} %`;
					},
				},
			},
		},
	};

	return (
		<div className="flex flex-col w-full p-8 bg-white rounded-lg">
			<Heading type="h2">{question}</Heading>

			<div className="flex">
				<SurveyTable
					charColors={chartColors}
					isNPS={false}
					data={answers}
					total={total}
					totalVote={getTotalVote()}
				/>
				<div className="ml-12">
					<Doughnut options={options} data={data} />
				</div>
			</div>
		</div>
	);
};

export default SurveyResponsesPieChartCard;
