import React from 'react';
import Text from 'components/atoms/Text';

export interface ICurrentYearGraph {
	title: string;
	total: number;
	value: number;
	backgroundColor: string;
	textColor: string;
}

const CurrentYearGraph: React.FC<ICurrentYearGraph> = ({
	title,
	value,
	total,
	backgroundColor,
	textColor,
}) => {
	const getPercentage = () => {
		return Math.round((value * 100) / total);
	};

	return (
		<div>
			<Text>{title}</Text>
			<div className="bg-[#EFF2F7] h-2 my-2 rounded-lg">
				<div
					className={`${backgroundColor} h-full ${
						getPercentage() === 100 ? 'rounded-lg' : 'rounded-l-lg'
					}`}
					style={{ width: `${getPercentage()}%` }}
				/>
			</div>
			<Text color={textColor}>{value}</Text>
		</div>
	);
};

export default CurrentYearGraph;
