import { useEffect } from 'react';
import { IIncident, ISection1ErrorStates } from 'types/VehicleIncidents';
import { IVehicleIncidentSection1 } from 'types/VehicleIncidents';

export const useGetIncidentSection1 = (
	isSuccess: boolean,
	data: IIncident | undefined,
	setIncidentSection1: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection1>
	>,
	setSection1IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection1ErrorStates>
	>
) => {
	useEffect(() => {
		if (isSuccess && data) {
			const {
				branch,
				client_name,
				incident_description,
				incident_date,
				incident_time,
				job_details,
				location,
				mv_incident,
				reported_date,
				altusRegistration,
				driverName,
				licenseClass,
				licenseExpiry,
				thirdParty,
				thirdParties,
			} = data.incidentData.incident_section_1;

			setIncidentSection1({
				description: incident_description,
				location,
				client: client_name,
				jobDetails: job_details,
				altusRegistration,
				driverName,
				licenseClass,
				licenseExpiry,
				incident_date: new Date(incident_date),
				reported_date: new Date(reported_date),
				branch,
				incident_time,
				mv_incident,
				thirdParty,
				thirdParties:
					thirdParties && thirdParties.length > 0
						? thirdParties
						: [
								{
									id: 1,
									thirdParty_name: '',
									thirdParty_vehicle_registration: '',
									thirdParty_licence_number: '',
									thirdParty_phone_number: '',
								},
						  ],
			});

			setSection1IncidentErrorStates({
				section_1_incident_description: !!incident_description,
				section_1_location: !!location,
				section_1_client_name: !!client_name,
				section_1_job_details: !!job_details,
				section_1_incident_date: incident_date ? true : false,
				section_1_reported_date: reported_date ? true : false,
				section_1_incident_time: !!incident_time,
			});
		}
	}, [isSuccess, data, setIncidentSection1, setSection1IncidentErrorStates]);
};
