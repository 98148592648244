import React from 'react';

export interface ISeverityLabel {
	className?: string;
	status: 'low' | 'medium' | 'high' | 'severe' | 'extreme';
}

const SeverityLabel: React.FC<ISeverityLabel> = ({
	className = '',
	status,
}) => {
	return (
		<div
			className={`flex justify-center items-center flex-grow-0 flex-shrink-0 w-[90px] relative gap-2 p-1 rounded-2xl bg-[#FFECCB] ${className}`}
		>
			<p
				className={`flex-grow-0 flex-shrink-0 capitalize text-sm text-left text-secondary-500`}
			>
				{status}
			</p>
		</div>
	);
};

export default SeverityLabel;
